import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ImageIcon from "@mui/icons-material/Image";

import {
  API_Fundamental_Create,
  API_Milestone,
  Age_Group,
} from "types/project";
import useStore from "store/store";
import { translation } from "constants/translation";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";
import { SubtitleWithAddIcon } from "components/ProjectForms/ActivityFormComponents";
import Image from "components/Image/Image";
import Logo from "images/logo-2.png";

import { MilestoneSortableList } from "./MilestoneDnd/MilestoneSortableList";
import useSnack from "hooks/useSnack";
import { deleteMilestone } from "api/fundamental-api";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import { TEXT_FIELD_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";
import {
  BLOOMS,
  BLOSSOMS,
  BUDS,
  SPROUTLINGS,
  SPROUTS,
} from "constants/project-lesson-activity";

const NameField = ({
  data,
  setData,
  onChange,
}: {
  data: API_Fundamental_Create;
  setData: React.Dispatch<React.SetStateAction<API_Fundamental_Create>>;
  onChange?: (input: string) => void;
}) => (
  <TextField
    label={translation.name}
    value={data.name}
    onChange={(event) => {
      setData((prev) => ({ ...prev, name: event.target.value }));
      onChange && onChange(event.target.value);
    }}
    required
  />
);

const PriorityField = ({
  data,
  setData,
  onChange,
}: {
  data: API_Fundamental_Create;
  setData: React.Dispatch<React.SetStateAction<API_Fundamental_Create>>;
  onChange?: (input: number) => void;
}) => {
  const openSnack = useSnack();
  const { profileDetails } = useStore((state) => ({
    profileDetails: state.profileDetails,
  }));
  return (
    <TextField
      type="number"
      InputProps={{ inputProps: { min: 0 } }}
      label={"Developmental Goal Order"}
      value={data.priority ?? 0}
      onChange={(event) => {
        const priority = Number(event.target.value);
        if (profileDetails.role == "CE" || profileDetails.role == "WAD") {
          setData((prev) => ({ ...prev, priority }));
          onChange && onChange(priority);
        } else {
          openSnack("You must be a Content Editor to change priority!", false);
        }
      }}
      required
    />
  );
};

const DescriptionField = ({
  data,
  setData,
  onChange,
}: {
  data: API_Fundamental_Create;
  setData: React.Dispatch<React.SetStateAction<API_Fundamental_Create>>;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>{translation.description}</Typography>

    <RichCKEditor
      data={data.description}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setData((prev) => ({ ...prev, description: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

const TitleField = ({
  data,
  setData,
  onChange,
}: {
  data: API_Fundamental_Create;
  setData: React.Dispatch<React.SetStateAction<API_Fundamental_Create>>;
  onChange?: (input: string) => void;
}) => (
  <TextField
    label={translation.title}
    value={data.title}
    onChange={(event) => {
      setData((prev) => ({ ...prev, name: event.target.value }));
      onChange && onChange(event.target.value);
    }}
    required
  />
);

// dnd have 3 layers: DndContext, SortableContext, Item>
const MilestoneFieldWithDnd = ({
  currDevelopmentalGoal,
  currMilestones,
  setCurrMilestones,
  onChange,
  handleClose,
}: {
  currDevelopmentalGoal: API_Fundamental_Create;
  currMilestones: API_Milestone[];
  setCurrMilestones: React.Dispatch<React.SetStateAction<API_Milestone[]>>;
  onChange?: () => void;
  handleClose: () => void;
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      {currMilestones.length > 0 && (
        <Typography>{translation.milestone}</Typography>
      )}
      <MilestoneSortableList
        items={currMilestones}
        setItems={setCurrMilestones}
        renderItem={(item) => {
          return (
            <MilestoneSortableList.Item id={item.id}>
              <MilestoneField
                item={item}
                currMilestones={currMilestones}
                setCurrMilestones={setCurrMilestones}
              />
              <MilestoneSortableList.DragHandle />
            </MilestoneSortableList.Item>
          );
        }}
      />
      <Box sx={{ ml: currMilestones.length > 0 ? 3 : 0 }}>
        <SubtitleWithAddIcon
          subtitle={translation.milestone}
          handleAdd={() => {
            const updatedMilestones = [
              ...currMilestones,
              {
                id: `unassigned-id-${currMilestones.length}`,
                fundamental: currDevelopmentalGoal.id,
                name: "",
                age: SPROUTLINGS as Age_Group,
                files: [],
                new_files: [],
                weight: currMilestones.length + 1,
              },
            ];
            setCurrMilestones(updatedMilestones);
            onChange && onChange();
          }}
        />
      </Box>
    </Box>
  );
};


const MilestoneField = ({
  item,
  currMilestones,
  setCurrMilestones,
}: {
  item: API_Milestone;
  currMilestones: API_Milestone[];
  setCurrMilestones: React.Dispatch<React.SetStateAction<API_Milestone[]>>;
}) => {
  const openSnack = useSnack();
  const [updatedMilestoneData, setUpdatedMilestoneData] =
    useState<API_Milestone>({
      id: item.id,
      fundamental: item.fundamental,
      name: item.name,
      age: item.age,
      // description: item.description,
      // files: item.files,
      // new_files: [],
      weight: item.weight,
    });

  const { currLanguage, setIsRefreshAllFundamental } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setIsRefreshAllFundamental: state.setIsRefreshAllFundamental,
  }));

  // To fix the re-order jumping around issue, make sure when re-ordering, if the updatedMilestoneData value doesn't change, don't run this useEffect
  // Make sure that the weight doesn't get change upon other value change.

  useEffect(() => {
    const updatedMilestones: API_Milestone[] = JSON.parse(
      JSON.stringify(currMilestones)
    );
    const currMilestoneIndex = currMilestones.findIndex(
      (currMilestone) => currMilestone.id === item.id
    );
    updatedMilestones[currMilestoneIndex].name = updatedMilestoneData.name;
    updatedMilestones[currMilestoneIndex].age = updatedMilestoneData.age;

    setCurrMilestones(updatedMilestones);
  }, [updatedMilestoneData.name, updatedMilestoneData.age]);

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteMilestone(Number(item.id), currLanguage);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setIsRefreshAllFundamental(true);
      setCurrMilestones(res);
      openSnack(translation.removedDevelopmentalGoal, true);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! ${item.name} will be deleted for all languages.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.name]);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Milestone?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          width: "100%",
        }}
        key={`milestone-${item.id}`}
      >
        <Typography>{`${item.weight}.`}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "90%",
          }}
        >
          {/* Age here */}

          <FormControl required>
            <InputLabel id="demo-simple-select-label">
              {translation.level}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={TEXT_FIELD_STYLE}
              value={updatedMilestoneData.age}
              label={translation.age}
              onChange={(event) => {
                setUpdatedMilestoneData((prev) => ({
                  ...prev,
                  age: event.target.value as Age_Group,
                }));
              }}
            >
              <MenuItem value={SPROUTS}>
                {translation.sprouts_with_age}
              </MenuItem>
              <MenuItem value={SPROUTLINGS}>
                {translation.sproutlings_with_age}
              </MenuItem>

              <MenuItem value={BUDS}>{translation.buds_with_age}</MenuItem>
              <MenuItem value={BLOSSOMS}>
                {translation.blossoms_with_age}
              </MenuItem>
              <MenuItem value={BLOOMS}>{translation.blooms_with_age}</MenuItem>
            </Select>
          </FormControl>

          {/* Age end here  */}
          <TextField
            fullWidth
            label={translation.name}
            value={updatedMilestoneData.name}
            onChange={(event) => {
              setUpdatedMilestoneData((prev) => ({
                ...prev,
                name: event.target.value,
              }));
            }}
            sx={{
              textDecoration: "none",
            }}
          />
        </Box>

        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (typeof item.id === "string" && item.id.includes("unassigned")) {
              setCurrMilestones(
                currMilestones.filter(
                  (currMilestone) => currMilestone.id !== item.id
                )
              );
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Box>
    </>
  );
};

// const MilestoneFileField = ({
//   updatedMilestoneData,
//   setUpdatedMilestoneData,
//   multiple = false,
//   language,
// }: {
//   updatedMilestoneData: API_Milestone;
//   setUpdatedMilestoneData: React.Dispatch<React.SetStateAction<API_Milestone>>;
//   multiple?: boolean;
//   language?: string;
// }) => {
//   const openSnack = useSnack();
//   const { currLanguage } = useStore((state) => ({
//     currLanguage: state.currLanguage,
//   }));
//   const handleFileDelete = async (fileId: number) => {
//     const res = await deleteMilestoneFile(fileId, currLanguage);
//     if (res === "success") {
//       openSnack("Milestone File is deleted!", true);
//       setUpdatedMilestoneData((prev) => ({
//         ...prev,
//         files: [...prev.files.filter((file) => file.id !== fileId)],
//       }));
//     }
//   };
//   return (
//     <Box>
//       <Typography sx={{ mb: "8px" }}>
//         {translation.attachment_if_any}
//       </Typography>

//       <input
//         type="file"
//         id="image-button-files"
//         multiple={multiple}
//         hidden
//         onChange={(event) => {
//           const newFiles: File[] = Array.prototype.slice.call(
//             event.target.files
//           );

//           // setUploadedFiles((prev) => [...prev, ...newFiles]);
//           setUpdatedMilestoneData((prev) => ({
//             ...prev,
//             new_files: [...prev.new_files, ...newFiles],
//           }));
//         }}
//       />

//       <label htmlFor="image-button-files">
//         <IconButton
//           component="span"
//           sx={{
//             borderRadius: "8px",
//             backgroundColor: "primary.light",
//             "&:hover": {
//               backgroundColor: "primary.main",
//             },
//           }}
//         >
//           <ImageIcon sx={{ color: "txt.secondary" }} />
//         </IconButton>
//       </label>

//       {/* Rendering existing files */}
//       <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: "16px" }}>
//         {updatedMilestoneData.files.map((file, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               gap: 0.5,
//             }}
//           >
//             <a href={file.source} target="_blank" rel="noreferrer">
//               {file.name}
//             </a>

//             <ClearIcon
//               sx={{
//                 color: "txt.secondary",
//                 cursor: "pointer",
//                 backgroundColor: "primary.light",
//                 borderRadius: "50%",
//                 p: "3px",
//                 transition: "all 100ms linear",
//                 "&:hover": {
//                   p: "4px",
//                   backgroundColor: "primary.main",
//                   transition: "all 100ms linear",
//                 },
//               }}
//               onClick={() => {
//                 handleFileDelete(file.id);
//               }}
//             />
//           </Box>
//         ))}
//       </Box>

//       {/* Rendering new files */}
//       <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: "16px" }}>
//         {updatedMilestoneData.new_files.map((file, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               gap: 0.5,
//             }}
//           >
//             <a
//               href={URL.createObjectURL(file)}
//               target="_blank"
//               rel="noreferrer"
//             >
//               {file.name}
//             </a>

//             <ClearIcon
//               sx={{
//                 color: "txt.secondary",
//                 cursor: "pointer",
//                 backgroundColor: "primary.light",
//                 borderRadius: "50%",
//                 p: "3px",
//                 transition: "all 100ms linear",
//                 "&:hover": {
//                   p: "4px",
//                   backgroundColor: "primary.main",
//                   transition: "all 100ms linear",
//                 },
//               }}
//               onClick={() => {
//                 setUpdatedMilestoneData((prev) => ({
//                   ...prev,
//                   new_files: [
//                     ...prev.new_files.filter(
//                       (uploadedFile) => uploadedFile.name !== file.name
//                     ),
//                   ],
//                 }));
//               }}
//             />
//           </Box>
//         ))}
//       </Box>
//     </Box>
//   );
// };

const ImageField = ({
  image,
  setImage,
}: {
  image: File | string;
  setImage: React.Dispatch<React.SetStateAction<File | string>>;
}) => {
  const MAX_IMAGE_SIZE_MB = 5; // Set the maximum image size limit in megabytes
  const openSnack = useSnack();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile: File | null = event.target.files
      ? event.target.files[0]
      : null;

    if (!newFile) return;

    if (newFile.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
      // Display snack message for invalid images
      openSnack(
        `Images should be less than ${MAX_IMAGE_SIZE_MB}MB or compressed.`,
        false
      );
    } else {
      setImage(newFile);
    }

    event.target.value = ""; // Clear the input file value
  };

  return (
    <Box>
      <Typography sx={{ mb: "8px" }}>{translation.image}</Typography>

      <input
        type="file"
        id="image-button-image"
        hidden
        onChange={handleImageChange}
      />

      <label htmlFor="image-button-image">
        <IconButton
          component="span"
          sx={{
            borderRadius: "8px",
            backgroundColor: "primary.light",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
        >
          <ImageIcon sx={{ color: "txt.secondary" }} />
        </IconButton>
      </label>
      {image && (
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: "16px" }}>
          <Box
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "10px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Image
              src={
                typeof image === "string" ? image : URL.createObjectURL(image)
              }
              alt={Logo}
            />
            <ClearIcon
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
                color: "txt.secondary",
                cursor: "pointer",
                backgroundColor: "primary.light",
                borderRadius: "50%",
                p: "3px",
                transition: "all 100ms linear",
                "&:hover": {
                  p: "4px",
                  backgroundColor: "primary.main",
                  transition: "all 100ms linear",
                },
              }}
              onClick={() => {
                setImage("");
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export {
  NameField,
  DescriptionField,
  MilestoneFieldWithDnd,
  ImageField,
  PriorityField,
  
};
