import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  Stack,
  SelectChangeEvent,
  useTheme,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  ButtonContainer,
  AddLearningStoryButton,
  AddIconStyled,
  ButtonText,
  Date,
  Story,
  DateHeading,
  SmallScreenTimeFrameButton,
} from "./styles";
import { truncateText } from "components/ChildrenPortfolioPDF/Templates/DevelopmentalGoalComponents/helper";
import PermissionModal from "components/Organisms/PermissionModal/PermissionModal";
import LearningStoryModal from "components/Organisms/LearningStoryModal/LearningStoryModal";
import { useParams } from "react-router-dom";
import { getChildImages } from "api/gallery-api";
import PhotoAlbumModal from "components/Organisms/PhotoAlbumModal/PhotoAlbumModal";
import { ImageType } from "../PhotoAlbumScreenComponents/PhotoAlbumComponents";
import {
  fetchLearningStories,
  createlearningStoryAPI,
  updateLearningStoryAPI,
  deleteLearningStoryAPI,
  fetchLearningStoriesWithTimeFrame,
} from "api/learning-stories-api";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import moment from "moment";
import useLearningStories from "hooks/LearningStory/useLearningStories";

const LearningStoriesScreen = ({
  isParentView = false,
}: {
  isParentView?: boolean;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const openSnack = useSnack();
  const [isLoading, setIsLoading] = useState(false);
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [editLearningModal, setEditLearningModal] = useState(false);
  const [openDeletePermissionModal, setOpenDeletePermissionModal] =
    useState(false);
  const [deleteStoryId, setDeleteStoryId] = useState<number>(-1);
  const [editingStory, setEditingStory] = useState<{
    id: number;
    date: string;
    image: string;
    story: string;
    fundamental: string;
    milestone: string;
  } | null>(null);
  const { childID } = useParams();
  const [caption, setCaption] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const groupID = localStorage.getItem("curr_group_id");
  const [photoAlbumModal, setPhotoAlbumModal] = useState(false);
  const [timeframe, setTimeframe] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const [open, setOpen] = useState(false);
  const [photoAlbum, setPhotoAlbum] = useState<ImageType[]>([]);
  const [openLearningStoryModal, setOpenLearningStoryModal] = useState(false);
  const [input, setInput] = useState<string | File | null>(null);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [selectedImage, setSelectedImage] = useState<{
    link: string;
    caption: string;
    date: string;
  } | null>(null);

  const { stories, error, getStories } = useLearningStories(
    groupID || "",
    childID || "",
    timeframe
  );

  const groupedStories = Object.entries(stories).map(
    ([date, storiesOnDate]) => ({
      date,
      stories: storiesOnDate,
    })
  );

  // Calculate total pages based on grouped stories
  let totalItems = 0;
  let paginatedGroups = [];
  let currentGroup: {
    date: string;
    stories: {
      fundamental: any;
      milestone: any;
      id: number;
      date: string;
      image: string;
      story: string;
    }[];
  }[] = [];

  // Ensure that paginated groups retain the order as received
  groupedStories.forEach((group) => {
    let remainingStories = [...group.stories];
    while (remainingStories.length > 0) {
      const remainingSlots = itemsPerPage - totalItems;
      if (remainingStories.length <= remainingSlots) {
        currentGroup.push({ date: group.date, stories: remainingStories });
        totalItems += remainingStories.length;
        remainingStories = [];
      } else {
        currentGroup.push({
          date: group.date,
          stories: remainingStories.slice(0, remainingSlots),
        });
        remainingStories = remainingStories.slice(remainingSlots);
        totalItems += remainingSlots;
      }

      if (totalItems >= itemsPerPage) {
        paginatedGroups.push(currentGroup);
        currentGroup = [];
        totalItems = 0;
      }
    }
  });

  if (currentGroup.length > 0) {
    paginatedGroups.push(currentGroup);
  }

  const pageCount = paginatedGroups.length;
  const displayedGroups = paginatedGroups[currentPage - 1] || [];

  useEffect(() => {
    const initializeChildImages = async () => {
      const res = await getChildImages(
        Number(childID),
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        const data: ImageType[] = res.map((image) => ({
          id: image.id,
          link: image.image,
          caption: image.caption,
          created_at: image.created_at,
        }));

        setPhotoAlbum(data);
      }
    };

    initializeChildImages();
  }, [childID]);

  const handleTimeframeChange = (event: SelectChangeEvent<string>) => {
    setTimeframe(event.target.value);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const fetchPhoto = (imageUrl: string | undefined) => {
    if (!imageUrl) return;

    return fetch(imageUrl, {
      headers: { "Cache-Control": "no-cache" },
    })
      .then((res) => res.blob())
      .then((imageBlob) => {
        const file = new File([imageBlob], "image.jpg", {
          type: imageBlob.type,
        });
        setImageFile(file);
      })
      .catch((err) => {
        console.error("Failed to fetch image from S3:", err);
        openSnack("Failed to fetch image. Please try again.", false);
      });
  };

  const handleImageSelection = (
    selectedImages: {
      index: number;
      link: string;
      caption: string;
      date: string;
    }[]
  ) => {
    setSelectedImage(selectedImages[0]);
    fetchPhoto(selectedImages[0].link);
    setCaption(selectedImages[0].caption);
    setDate(moment(selectedImages[0].date).format("YYYY-MM-DD") as string);
    setPhotoAlbumModal(false);
    setOpenLearningStoryModal(true);
  };

  const createLearningStory = async (data: {
    story?: string;
    date?: string;
    photo?: File | undefined;
    parentView: boolean;
    fundamental: string | undefined;
    milestone: string | undefined;
  }) => {
    setIsLoading(true);

    const learningStoryData = {
      child_id: Number(childID),
      group_id: Number(groupID),
      story: data.story || "",
      date: data.date || "",
      fundamental_id: data.fundamental || undefined,
      milestone_id: data.milestone || undefined,
      image: data.photo || undefined,
      accessToken: localStorage.getItem("access"),
    };

    const res = await createlearningStoryAPI(learningStoryData);

    if (res !== "string") {
      setImageFile(undefined);
      setOpenLearningStoryModal(false);
      setCaption("");
      setDate("");
      openSnack(translation.createSuccess, true);
      getStories();
      setIsLoading(false);
    } else {
      openSnack(translation.failed_upload, false);
    }
  };

  const updateLearningStory = async (
    storyId: number,
    data: {
      story?: string;
      date?: string;
      photo?: File | null;
      parentView: boolean;
      fundamental: string | undefined;
      milestone: string | undefined;
    }
  ) => {
    setIsLoading(true);

    const updateLearningStoryData = {
      storyId: storyId,
      child_id: Number(childID),
      group_id: Number(groupID),
      story: data.story || "",
      date: data.date || "",
      fundamental_id: data.fundamental || undefined,
      milestone_id: data.milestone || undefined,
      image: data.photo || undefined,
      accessToken: localStorage.getItem("access"),
    };

    const res = await updateLearningStoryAPI(updateLearningStoryData);

    if (res) {
      setEditLearningModal(false);
      setCaption("");
      setDate("");
      openSnack(translation.updateSuccess, true);
      getStories();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      openSnack(translation.updateFailed, true);
    }
  };

  const handleDeleteStory = async (id: number) => {
    // Delete story logic
    const res = await deleteLearningStoryAPI(id);
    if (res) {
      setOpenDeletePermissionModal(false);
      setDeleteStoryId(-1);
      openSnack(translation.deleteSuccess, true);
      getStories();
    } else {
      openSnack(translation.deleteFailed, false);
    }
  };

  const getButtonStyles = (selectedTimeframe: string) => ({
    color: timeframe === selectedTimeframe ? "#000" : "#fff",
    borderRadius: "100px",
    //!important added to override any other styling
    backgroundColor:
      timeframe === selectedTimeframe
        ? "#F2F2F2 !important"
        : "transparent !important",
    padding: "4px 8px",
  });

  return (
    <Box>
      {/* Container create learning story button and timeFrame */}
      <ButtonContainer>
        {!isXs && (
          <FormControl variant="outlined" size="small" sx={{ width: "200px" }}>
            <InputLabel>Timeframe</InputLabel>
            <Select
              value={timeframe}
              onChange={handleTimeframeChange}
              label="Timeframe"
            >
              <MenuItem value="All">All Stories</MenuItem>
              <MenuItem value="Day">Day</MenuItem>
              <MenuItem value="Month">Month</MenuItem>
              <MenuItem value="Year">Year</MenuItem>
            </Select>
          </FormControl>
        )}
        <AddLearningStoryButton
          variant="contained"
          startIcon={<AddIconStyled />}
          onClick={() => setOpen(true)}
          style={{ width: isXs ? "100%" : "" }}
        >
          <ButtonText>Create Learning Story</ButtonText>
        </AddLearningStoryButton>
      </ButtonContainer>

      {/* Cards to display story image, text, date */}
      <Grid container spacing={2}>
        {displayedGroups.map(({ date, stories }) => (
          <React.Fragment key={date}>
            <Grid item xs={12}>
              {timeframe === "Day" ? (
                <DateHeading>{moment(date).format("DD MMMM YYYY")}</DateHeading>
              ) : timeframe === "Month" ? (
                <DateHeading>{moment(date).format("YYYY")}</DateHeading>
              ) : null}
            </Grid>
            {stories.map((story) => (
              <Grid item xs={12} sm={6} md={4} key={story.id}>
                <Card
                  onClick={() => {
                    setEditingStory({
                      id: story.id,
                      date: story.date,
                      image: story.image,
                      story: story.story,
                      fundamental: story.fundamental?.id,
                      milestone: story.milestone?.id,
                    });
                    setEditLearningModal(true);
                    fetchPhoto(story.image);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Box sx={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      alt="Learning Story"
                      height="206"
                      image={story.image}
                    />
                    {timeframe === "Year" ? (
                      <Date>{moment(story.date).format("YYYY")}</Date>
                    ) : timeframe === "Month" ? (
                      <Date>{moment(story.date).format("MMMM")}</Date>
                    ) : null}
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "white",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteStoryId(story.id);
                        setOpenDeletePermissionModal(true);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                  <CardContent>
                    <Story>{truncateText(story.story, 1, 170)}</Story>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </React.Fragment>
        ))}
      </Grid>

      {/* Pagination */}
      {pageCount > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Box>
      )}

      {/* Sticky Bar For Small Screen */}
      {isXs && (
        <Box
          sx={{
            position: "fixed",
            bottom: 30,
            left: "50%",
            transform: "translate(-50%, 50%)",
            width: "311px",
            height: "40px",
            padding: "6px 8px",
            backgroundColor: "#91C6C5",
            display: "flex",
            justifyContent: "space-around",
            zIndex: 100,
            borderRadius: "100px",
          }}
        >
          <Button
            sx={getButtonStyles("All")}
            onClick={() => setTimeframe("All")}
          >
            <SmallScreenTimeFrameButton>All Stories</SmallScreenTimeFrameButton>
          </Button>
          <Button
            sx={getButtonStyles("Year")}
            onClick={() => setTimeframe("Year")}
          >
            <SmallScreenTimeFrameButton>Years</SmallScreenTimeFrameButton>
          </Button>
          <Button
            sx={getButtonStyles("Day")}
            onClick={() => setTimeframe("Day")}
          >
            <SmallScreenTimeFrameButton>Days</SmallScreenTimeFrameButton>
          </Button>
          <Button
            sx={getButtonStyles("Month")}
            onClick={() => setTimeframe("Month")}
          >
            <SmallScreenTimeFrameButton>Months</SmallScreenTimeFrameButton>
          </Button>
        </Box>
      )}

      {/* Permission Modal */}
      <PermissionModal
        isOpen={open}
        setIsOpen={setOpen}
        title="ADD A PHOTO TO YOUR LEARNING STORY!"
        description={[
          <React.Fragment>
            Would you like to upload a photo from your computer
            <br />
            or <br />
            choose a photo from the photo album?
          </React.Fragment>,
        ]}
        onConfirmLabel="Photo Album"
        onCancelLabel="Upload Photo"
        onClose={() => {
          const input = document.createElement("input");
          input.type = "file";
          input.accept = "image/jpg, image/jpeg, image/png, image/webp";
          input.onchange = (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
              if (file.size > 5 * 1024 * 1024) {
                openSnack("File size should be less than 5MB", false);
                return;
              }
              setImageFile(file);
              setOpen(false);
              setOpenLearningStoryModal(true);
            }
          };
          input.click();
        }}
        onConfirm={() => {
          setPhotoAlbumModal(true);
          setOpen(false);
        }}
      />

      {/* Learning Story Modal */}
      <LearningStoryModal
        title="Add Learning Story"
        image={imageFile}
        open={openLearningStoryModal}
        setOpen={() => {
          setOpenLearningStoryModal(false);
          setImageFile(undefined);
          setImageUrl("");
          setCaption("");
          setDate("");
        }}
        onSave={createLearningStory}
        orientation="landscape"
        date={date} // Pass the date here
        story={caption} // Pass the caption here
        isLoading={isLoading}
      />

      {/* Edit Learning Story Modal */}
      <LearningStoryModal
        title="Edit Learning Story"
        image={imageFile}
        open={editLearningModal}
        setOpen={() => {
          setEditLearningModal(false);
          setCaption("");
          setDate("");
        }}
        onSave={(data) => {
          if (editingStory?.id !== undefined) {
            updateLearningStory(editingStory.id, data);
          }
        }}
        orientation="landscape"
        date={editingStory?.date}
        story={editingStory?.story || ""}
        selectedDevGoal={editingStory?.fundamental}
        selectedMileStones={editingStory?.milestone}
        isLoading={isLoading}
      />

      {/* Photo Album Modal */}
      <PhotoAlbumModal
        open={photoAlbumModal}
        setOpen={setPhotoAlbumModal}
        images={photoAlbum}
        onSelection={handleImageSelection}
      />

      {/* Permission Modal */}
      <PermissionModal
        isOpen={openDeletePermissionModal}
        setIsOpen={setOpenDeletePermissionModal}
        title="DELETE LEARNING STORY!"
        description={[
          <React.Fragment>
            Are You Sure You Want To Delete This Learning Story?
          </React.Fragment>,
        ]}
        onConfirmLabel="Yes"
        onCancelLabel="No"
        onClose={() => {
          setOpenDeletePermissionModal(false);
          setDeleteStoryId(-1);
        }}
        onConfirm={() => {
          handleDeleteStory(deleteStoryId);
        }}
      />
    </Box>
  );
};

export default LearningStoriesScreen;
