import {
  Box,
  Modal,
  Fade,
  Backdrop,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import {
  TitleContainer,
  HeadingStyle,
  ModalContainer,
  HeaderContainer,
  ImageBox,
  ImageContainer,
  ImageCaption,
} from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PhotoAlbumModalProps } from "./types";
import { useTheme } from "@mui/material/styles";

const PhotoAlbumModal: React.FC<PhotoAlbumModalProps> = ({
  open,
  setOpen,
  images,
  onSelection,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleImageClick = (
    index: number,
    image: { link: string; caption: string; created_at: string }
  ) => {
    if (onSelection) {
      onSelection([
        {
          index,
          link: image.link,
          caption: image.caption,
          date: image.created_at,
        },
      ]);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <ModalContainer isMobile={isMobile}>
            <HeaderContainer>
              <TitleContainer>
                <IconButton onClick={() => setOpen(false)}>
                  <ArrowBackIcon />
                </IconButton>
                <HeadingStyle>Photo Album</HeadingStyle>
              </TitleContainer>
            </HeaderContainer>
            <ImageBox>
              {images?.map((image, index) => (
                <ImageContainer
                  key={image.id}
                  onClick={() => handleImageClick(index, image)}
                  isMobile={isMobile}
                >
                  <img
                    src={image.link}
                    alt={image.caption}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <ImageCaption>{image.caption}</ImageCaption>
                </ImageContainer>
              ))}
            </ImageBox>
          </ModalContainer>
        </Fade>
      </Modal>
    </>
  );
};

export default PhotoAlbumModal;
