import React from "react";
import { TextField } from "@mui/material";
import { API_Fundamental_Create } from "types/project";


interface WeightageFieldProps {
  data: API_Fundamental_Create;
  setData: React.Dispatch<React.SetStateAction<API_Fundamental_Create>>;
  onChange?: (input: number) => void;
}

const WeightageField: React.FC<WeightageFieldProps> = ({ data, setData, onChange }) => {
  
  const handleWeightageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const weightage = parseFloat(event.target.value) || 0;
    setData((prev) => ({ ...prev, weightage }));
    if (onChange) {
      onChange(weightage);
    }
  };

  return (
    <TextField
      type="number"
      InputProps={{ inputProps: { min: 0, step: 1 } }} 
      label="Weightage"
      value={data.weightage ?? ""}
      onChange={handleWeightageChange}
      required
    />
  );
};

export default WeightageField;
