import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PersonIcon from "@mui/icons-material/Person";

const testimonials = [
  {
    name: "Jenny Wilson",
    role: "Bluejay Class",
    testimonial:
      "The key takeaway for me is that respect goes both ways. As adults, we should demonstrate respect to children and lead with respect. Thank you for this valuable lesson!",
  },
  {
    name: "Robert Fox",
    role: "Bunny 2023",
    testimonial:
      "We are incredibly thankful to have had this session. It has inspired us to improve our learning environment significantly. We are excited to implement the insights we gained.",
  },
  {
    name: "Devon Lane",
    role: "Cerigo Class",
    testimonial:
      "This session has opened our eyes to numerous strategies that can enhance our learning environment. We are committed to applying these strategies to create a better, more engaging space for everyone.",
  },
  {
    name: "Jane Cooper",
    role: "Fuji",
    testimonial:
      "The session provided invaluable insights on organizing every corner of the classroom to foster a positive learning environment. We now have a clear vision of how to make our space more conducive to learning.",
  },
];

const TestimonialSection: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        py: 1,
        m: { xs: "24px 16px", sm: "80px 40px", md: "80px 60px", lg: "80px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mb: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{ alignSelf: "center", color: "#5C9391", fontWeight: 600, fontSize: 24 }}
        >
          What our teachers say
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
          <Typography sx={{ mr: 1 }}>View More</Typography>
          <ArrowRightAltIcon />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {testimonials.map((testimonial, index) => (
          <Box
            key={index}
            sx={{
              width: "303px",
              height: "363.5px",
              backgroundColor: "#F8FDFD",
              borderRadius: "4px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              p: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: "#323031",
                mb: 2,
              }}
            >
              {testimonial.testimonial}
            </Typography>
            <Box
              sx={{
                position: "absolute",
                bottom: "120px", // Adjusted to position above the dark green arch box
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
                transform: "translateY(50%)", // Move half into the dark green box
              }}
            >
              <Box
                sx={{
                  width: "94px",
                  height: "92px",
                  position: "relative",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="95"
                  height="92"
                  viewBox="0 0 95 92"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M65.7297 25.2204C66.4104 23.6803 66.9128 22.0116 67.1996 20.2455C68.7773 10.5285 63.2293 1.54287 54.8079 0.175548C46.3865 -1.19177 38.2806 5.577 36.7029 15.294C36.592 15.977 36.5164 16.6563 36.4744 17.3297C34.6657 15.3505 32.4413 13.7533 29.8549 12.6905C19.4609 8.41946 7.15656 14.3956 2.37226 26.0387C-2.38206 37.6087 2.07884 50.421 12.3347 54.7723C7.72052 57.1284 4.45937 61.3129 3.93954 66.353C3.06422 74.8397 10.2929 82.5382 20.0852 83.5482C24.7739 84.0318 29.2003 82.8943 32.6366 80.6455C34.8586 86.2809 40.676 90.6455 47.8814 91.3887C57.6737 92.3987 66.3216 86.3376 67.1969 77.8509C67.2555 77.2827 67.2778 76.718 67.2654 76.1586C70.8769 76.9735 74.9267 76.6992 78.8213 75.1314C87.9533 71.4551 92.7735 62.059 89.5874 54.1445C88.7706 52.1156 87.507 50.381 85.9254 48.9852C92.9713 44.6261 96.3458 36.535 93.5484 29.5862C90.3623 21.6717 80.3764 18.236 71.2444 21.9123C69.169 22.7477 67.3163 23.8786 65.7297 25.2204ZM28.5216 54.0751C33.2253 55.8975 36.8516 59.3784 38.4998 63.5584C41.9362 61.3097 46.3625 60.1721 51.0512 60.6557C52.6261 60.8181 54.1346 61.1536 55.5538 61.6374C55.8249 56.3869 59.009 51.233 64.1402 48.0585C62.5587 46.6626 61.295 44.928 60.4783 42.8991C59.0442 39.3368 59.2321 35.4743 60.7052 31.9683C57.4302 34.6996 53.3018 36.0471 49.0946 35.364C46.3458 34.9177 43.9031 33.6597 41.9145 31.8296C41.9365 35.0131 41.3264 38.3067 40.012 41.5054C37.6435 47.2694 33.432 51.6445 28.5216 54.0751Z"
                    fill="#FEDB66"
                  />
                </svg>
                <Avatar
                  sx={{
                    width: "61px",
                    height: "61px",
                    backgroundColor: "#ffffff",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                  }}
                >
                  <PersonIcon sx={{ fontSize: 48, color: "#91C6C5" }} />
                </Avatar>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: -95,
                width: "335px",
                height: "218px",
                backgroundColor: "var(--Cyan-500, #91C6C5)",
                borderRadius: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: "#ffffff",
                  mb: 0.5,
                  marginTop: "50px"
                }}
              >
                {testimonial.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#ffffff",
                }}
              >
                {testimonial.role}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TestimonialSection;
