import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "components/Button/Button";
import LaptopImg from "images/teacher-landing-page/laptop.svg";

const LaptopIllustrationSection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: 1, md: 4, sm: 5, lg: 6 },
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#EBF8F8",
        borderRadius: "8px",
        height: { xs: "fit-content", md: "350px" },
        maxHeight: { xs: "fit-content", md: "350px" },
        overflowY: "visible",
        m: { xs: "24px 16px", sm: "80px 40px", md: "80px 60px", lg: "80px" },
        p: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "100%",
          maxWidth: "400px",
          height: "280.602px",
        }}
      >
        <Box
          component="img"
          src={LaptopImg}
          alt="My SVG"
          sx={{
            marginTop: '-10px',
            width: {
              xs: '100%',
              sm: 'unset',
            },
          }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "fit-content", md: "416px" },
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "flex-start" },
          gap: 1.5,
          ml: { xs: 0, md: 10 },
        }}
      >
        <Typography
          sx={{
            fontSize: 36,
            fontWeight: 600,
            color: "#323031",
            width: { xs: "fit-content", md: "289px" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          Check out our Teaching tool box
        </Typography>
        <Button
          buttonText="Get Access"
          onClick={() => {
            navigate("/");
          }}
          icon={false}
          arrow={false}
          type="button"
          style={{
            borderRadius: "4px",
          }}
        />
      </Box>
    </Box>
  );
};

export default LaptopIllustrationSection;
