import axios from "api/axiosConfig";

import {
  API_Child,
  API_Create_Child,
  API_Create_Child_Bulk,
  API_Create_Child_Response,
  API_Enrollment_Status,
  API_Enroll_Child,
  API_Enroll_Children,
  API_Enroll_Child_Response,
  API_Experience,
  API_Group_Admin_View_Lesson_Info,
  API_Group_Lesson_Info,
  API_Profile,
  API_Profile_Short,
  API_Reflection,
  API_Schedule,
  API_Schedule_Simple,
  API_Testimonial,
  API_Testimonial_Create,
  API_Testimonial_Update,
  API_Todo_List,
  API_Create_Todo_List,
  API_Update_Todo_List,
  API_Certification,
  // API_Create_Teacher,
  // API_Create_Teacher_Response,
} from "types/profile";
import {
  API_Group_Full,
  API_Group_Id_Only,
  API_Group_Quick_Question,
  API_Group_Simple,
} from "types/group";
import { AxiosError, CancelTokenSource } from "axios";
import { API_ModuleInterest_Response } from "types/prediscoveryquestionnaire";

export const getAccountProfile = async (
  profileId: string,
  accessToken: string | null
) => {
  const res = await axios.get(`en/profile/${profileId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Profile = await res.data;
  return data;
};

export const updateWalkthrough = async (profileID: number, mode: string, accessToken: string | null) => {
  const res = await axios.patch(
    `en/profile/${profileID}/update-walkthrough`,
    { mode },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Profile = await res.data;
  return data;
}

export const getChildProfile = async (
  profileId: string,
  accessToken: string | null
) => {
  const res = await axios.get(`en/profile/child/${profileId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Child = await res.data;
  return data;
};

export const getAllProfile = async (accessToken: string | null) => {
  try {
    const res = await axios.get("en/profile/list", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Profile[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createChild = async (childData: API_Create_Child) => {
  try {
    const res = await axios.post("en/profile/create", childData, {
      headers: { "content-type": "multipart/form-data" },
    });
    const data: API_Create_Child_Response = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createBulkChildProfiles = async (
  childData: API_Create_Child_Bulk[]
) => {
  try {
    const res = await axios.post("en/profile/create-bulk", childData, {
      headers: { "content-type": "application/json" },
    });

    const data: API_Create_Child_Response[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};
export const enrollChild = async (childData: API_Enroll_Child) => {
  try {
    const res = await axios.post("en/lms/enrollment/create", childData);
    const data: API_Enroll_Child_Response = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const enrollChildren = async (childrenData: API_Enroll_Children) => {
  try {
    const res = await axios.post("en/lms/enrollment/create", childrenData);
    const data: API_Enroll_Child_Response = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getEnrollmentChildren = async (projectId: number) => {
  try {
    const res = await axios.get(`en/lms/enrollment/list?project=${projectId}`);
    const data: API_Enroll_Child_Response[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getEnrollmentStatus = async (parentID: number) => {
  try {
    const res = await axios.get(`en/lms/enrollment/${parentID}`);
    const data: API_Enrollment_Status = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

//Parent
export const getChildRegisterParentProfile = async (school: number) => {
  const res = await axios.get("en/profile/child-parent-list", {
    params: { school: school },
  });
  const data: API_Profile_Short[] = await res.data;
  return data;
};

export const getAllParentProfile = async () => {
  const res = await axios.get("en/profile/parent-list");
  const data: API_Profile_Short[] = await res.data;
  return data;
};

//Child
export const getAllChildProfile = async () => {
  const res = await axios.get("en/profile/child-list/v2");
  const data: API_Child[] = await res.data;
  return data;
};

//Teacher
export const getAllTeacherProfile = async () => {
  const res = await axios.get("en/profile/teacher-list/v2");
  const data: API_Profile_Short[] = await res.data;
  return data;
};

//Trehaus Curriculum Specialist
export const getAllTcsProfile = async () => {
  const res = await axios.get("en/profile/trehaus-curriculum-specialist-list");
  const data: API_Profile_Short[] = await res.data;
  return data;
};

//TTA Content Editor
export const getAllTTAContentEditor = async () => {
  const res = await axios.get("en/profile/tta-content-editor-list");
  const data: API_Profile_Short[] = await res.data;
  return data;
};

//Content Editor
export const getAllContentEditor = async () => {
  const res = await axios.get("en/profile/content-editor-list");
  const data: API_Profile_Short[] = await res.data;
  return data;
};

//School Admin
export const getAllSchoolAdminProfile = async () => {
  const res = await axios.get("en/profile/school-admin-list");
  const data: API_Profile_Short[] = await res.data;
  return data;
};

//Site Admin
export const getAllSiteAdminProfile = async () => {
  const res = await axios.get("en/profile/site-admin-list");
  const data: API_Profile_Short[] = await res.data;
  return data;
};

// Group
export const getGroupList = async (currLanguage: string) => {
  const res = await axios.get(`${currLanguage}/profile/group/list/v2`);
  const data: API_Group_Full[] = await res.data;
  return data;
};

export const getGroupListBySchool = async (schoolId: number) => {
  const res = await axios.get(`en/profile/group/school/${schoolId}/list`);
  const data: API_Group_Simple[] = await res.data;
  return data;
};

export const getGroupById = async (groupId: string | number) => {
  const res = await axios.get(`en/profile/group/${groupId}`);
  const data: API_Group_Full = await res.data;
  return data;
};

export const getGroupQuickQuestionInfoById = async (
  groupId: string | number
) => {
  const res = await axios.get(
    `en/profile/group/${groupId}/quick-question-info`
  );
  const data: API_Group_Quick_Question = await res.data;
  return data;
};

//get edit info
export const getGroup = async (groupSlug: string) => {
  try {
    const res = await axios.get(`en/profile/group/slug/${groupSlug}`);
    const data: API_Group_Full = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createGroup = async (groupData: API_Group_Id_Only) => {
  const body = {
    name: groupData.name,
    description: groupData.description,
    age: groupData.age,
    communication_lvl: groupData.communication_lvl,
    school: groupData.school,
    profile: groupData.children.concat(groupData.teachers),
  };

  // console.log("body: ", body);
  if (typeof groupData.image !== "string" || groupData.image === null) {
    Object.assign(body, { image: groupData.image });
  }

  try {
    const res = await axios.post("en/profile/group/create", body, {
      headers: { "content-type": "multipart/form-data" },
    });
    const data: API_Group_Simple = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateGroup = async (groupData: API_Group_Id_Only) => {
  const body = {
    name: groupData.name,
    description: groupData.description,
    age: groupData.age,
    profile: groupData.children.concat(groupData.teachers),
  };

  if (typeof groupData.image !== "string" || groupData.image === null) {
    Object.assign(body, { image: groupData.image });
  }

  try {
    const res = await axios.patch(
      `en/profile/group/${groupData.id}/update`,
      body,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
    const data: API_Group_Id_Only = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteGroup = async (groupId: number) => {
  try {
    const res = await axios.delete(`en/profile/group/${groupId}/delete`);
    const data: API_Group_Id_Only = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// Prediscovery
export const getProfileModuleInterest = async (profileID: number) => {
  const res = await axios.get(`en/profile/moduleinterest/${profileID}/list`);
  const data: API_ModuleInterest_Response[] = await res.data;
  return data;
};

//Schedule objects
export const getGroupLessonsInfo = async (
  language: string,
  accessToken: string | null
) => {
  const res = await axios.get(
    `${language}/profile/group/current-next-lessons`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Group_Lesson_Info[] = await res.data;
  return data;
};

export const getCurrentNextGroupLessonsInfo = async (
  language: string,
  groupId: number,
  accessToken: string | null, 
  cancelToken: CancelTokenSource,
) => {
  const res = await axios.get(
    `${language}/profile/group/${groupId}/current-next-lessons`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      cancelToken: cancelToken.token,
    }
  );
  const data: API_Group_Lesson_Info = await res.data;
  return data;
};

export const getGroupLessonInfo = async (
  language: string,
  groupId: string | number,
  accessToken: string | null,
  cancelToken: CancelTokenSource
) => {
  const res = await axios.get(
    `${language}/profile/group/${groupId}/current-lessons`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      cancelToken: cancelToken.token,
    }
  );
  const data: API_Group_Admin_View_Lesson_Info = await res.data;
  return data;
};

export const getAllGroupForAdmin = async (
  language: string,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(`${language}/profile/admin-group/list`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Group_Admin_View_Lesson_Info[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// call this and set currSchedule with this result, then update when we click on the 'Done' button
export const getScheduleByProject = async (
  language: string,
  accessToken: string | null,
  projectId: number,
  groupId: number
) => {
  try {
    const res = await axios.get(
      `${language}/profile/schedule/project/${projectId}/group/${groupId}/get`,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Schedule_Simple = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getScheduleByGroup = async (
  language: string,
  groupId: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(
      `${language}/profile/schedule/group/${groupId}/get/v2`,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateScheduleActivityProgress = async (
  language: string,
  accessToken: string | null,
  scheduleId: number,
  activityId: number
) => {
  const body = {
    activity_id: activityId,
  };
  try {
    const res = await axios.post(
      `${language}/profile/schedule/${scheduleId}/update-schedule-complete-activity`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Schedule_Simple = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateScheduleLearningMomentProgress = async (
  language: string,
  accessToken: string | null,
  scheduleId: number,
  learningMomentId: number
) => {
  const body = {
    learning_moment_id: learningMomentId,
  };
  try {
    const res = await axios.post(
      `${language}/profile/schedule/${scheduleId}/update-schedule-complete-learning-moment`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Schedule_Simple = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateScheduleCheckpointProgress = async (
  language: string,
  accessToken: string | null,
  scheduleId: number,
  checkpointId: number
) => {
  const body = {
    checkpoint_id: checkpointId,
  };
  try {
    const res = await axios.post(
      `${language}/profile/schedule/${scheduleId}/update-schedule-complete-checkpoint`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Schedule_Simple = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateLessonProgress = async (
  language: string,
  accessToken: string | null,
  project: string,
  group: number
) => {
  const body = {
    project: project,
    group: group,
  };
  try {
    const res = await axios.post(
      `${language}/profile/update-current-lesson`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Schedule = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateExperience = async (payload: API_Experience) => {
  try {
    console.log(payload);
    payload.end_at = payload.end_at ? payload.end_at : undefined;
    console.log(payload);

    const res = await axios.patch(
      `en/profile/experience/${payload.id}/update`,
      payload
    );
    const data: API_Experience = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createExperience = async (payload: API_Experience) => {
  try {
    payload.end_at = payload.end_at ? payload.end_at : undefined;
    const res = await axios.post(`en/profile/experience/create`, payload);
    const data: API_Experience = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteExperience = async (experienceId: number) => {
  try {
    const res = await axios.delete(
      `en/profile/experience/${experienceId}/delete`
    );
    const data: API_Experience = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateTestimonial = async (payload: API_Testimonial_Update) => {
  try {
    const res = await axios.patch(
      `en/profile/testimonial/${payload.id}/update`,
      payload
    );
    const data: API_Testimonial = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createTestimonial = async (payload: API_Testimonial_Create) => {
  try {
    const res = await axios.post(`en/profile/testimonial/create`, payload);
    const data: API_Testimonial = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteTestimonial = async (testimonialId: number) => {
  try {
    const res = await axios.delete(
      `en/profile/testimonial/${testimonialId}/delete`
    );
    const data: API_Testimonial = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateReflection = async (payload: API_Reflection) => {
  try {
    const res = await axios.patch(
      `en/profile/reflection/${payload.id}/update`,
      payload
    );
    const data: API_Reflection = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createReflection = async (payload: API_Reflection) => {
  try {
    const res = await axios.post(`en/profile/reflection/create`, payload);
    const data: API_Reflection = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteReflection = async (reflectionId: number) => {
  try {
    const res = await axios.delete(
      `en/profile/reflection/${reflectionId}/delete`
    );
    const data: API_Reflection = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getCertificateByCredentialId = async (
  credentialId: string,
  accessToken: string | null
) => {
  const res = await axios.get(
    `en/profile/certification/get-by-credential-id/${credentialId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Certification = await res.data;
  return data;
};

export const updateCertification = async (payload: API_Certification) => {
  try {
    const res = await axios.patch(
      `en/profile/certification/${payload.id}/update`,
      payload
    );
    const data: API_Certification = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getToDoList = async (language: string, profileId: number) => {
  const res = await axios.get(`${language}/profile/${profileId}/todo-list`);
  const data: API_Todo_List = await res.data;
  return data;
};

export const createToDoList = async (
  language: string,
  todoList: API_Create_Todo_List
) => {
  try {
    const res = await axios.post(
      `${language}/profile/todo-list/create`,
      todoList
    );
    const data: API_Todo_List = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createCertification = async (payload: API_Certification) => {
  console.log("payload: ", payload);
  try {
    const res = await axios.post(`en/profile/certification/create`, payload);
    const data: API_Certification = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateToDoList = async (
  language: string,
  todoListId: number,
  todoList: API_Update_Todo_List
) => {
  try {
    const res = await axios.patch(
      `${language}/profile/todo-list/${todoListId}/update`,
      todoList
    );
    const data: API_Todo_List = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteCertification = async (certificateId: number) => {
  try {
    const res = await axios.delete(
      `en/profile/certification/${certificateId}/delete`
    );
    const data: API_Certification = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteToDoList = async (language: string, todoListId: number) => {
  try {
    await axios.delete(`${language}/profile/todo-list/${todoListId}/delete`);
    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const toggleEmailNotifications = async (
  profileId: number,
  accessToken: string | null,
  emailNotifications: boolean
) => {
  try {
    const response = await axios.patch(
      `en/profile/${profileId}/toggle-email-notifications`,
      { email_notifications: emailNotifications },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error toggling email notifications:", error);
    throw new Error("Failed to toggle email notifications");
  }
};
