import React from "react";
import { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import useStore from "store/store";

import { useParams } from "react-router-dom";
import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import { Project_Summary_Report, Query_Data } from "types/project";
import { SearchBar } from "containers/teacher/TeacherAnalytic/SecondRow";
import ModuleFilterButton from "./CompletedWorkComponents/ModuleFilterButton";
import { getProjectSummaryReportList } from "api/report-api";
import ProjectSummaryReportCard from "./ProjectSummaryReportComponents/ProjectSummaryReportCard";

const ChildPortfolioProjectSummaryScreen = () => {
  const { childID } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { currGroup, currLanguage } = useStore((state) => ({
    groupList: state.groupList,
    currGroup: state.currGroup,
    currLanguage: state.currLanguage,
  }));

  const [projectSummaryReportList, setProjectSummaryReportList] = useState<
    Project_Summary_Report[]
  >([]);

  // Search
  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });
  const [selectedModuleSlugs, setSelectedModuleSlugs] = useState<string[]>([]);

  const populateProjectSummaryReports = async () => {
    const res = await getProjectSummaryReportList({
      language: currLanguage,
      childId: Number(childID),
      groupId: currGroup.id,
      queryData: queryData,
      selectedModuleSlugs: selectedModuleSlugs,
    });

    if (typeof res !== "string") {
      setProjectSummaryReportList(res);
    }
  };

  useEffect(() => {
    populateProjectSummaryReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currGroup.id,
    queryData.query,
    queryData.startDate,
    queryData.endDate,
    selectedModuleSlugs.length,
  ]);

  // Preview
  // const [open, setOpen] = useState<boolean>(false);
  // const [selectedProjectSummaryReport, setSelectedProjectSummaryReport] =
  //   useState<Project_Summary_Report>();

  // const handleOpen = (projectSummaryReport: Project_Summary_Report) => {
  //   setOpen(true);
  //   setSelectedProjectSummaryReport(projectSummaryReport);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   setSelectedProjectSummaryReport(undefined);
  // };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 4,
          justifyContent: "left",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: {
              xs: "100%",
              lg: "70%",
            },
          }}
        >
          <SearchBar
            queryData={queryData}
            setQueryData={setQueryData}
            maxWidth={9999}
          />

          <Box
            sx={{
              display: "flex",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              gap: 2,
            }}
          >
            <StartAtDateField
              queryData={queryData}
              setQueryData={setQueryData}
            />

            <EndAtDateField queryData={queryData} setQueryData={setQueryData} />

            <ModuleFilterButton
              selectedModuleSlugs={selectedModuleSlugs}
              setSelectedModuleSlugs={setSelectedModuleSlugs}
              sx={{
                flex: { xs: "1 0 100%", sm: "auto" },
              }}
            />
          </Box>
          {isMobile ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  md: "flex-start",
                },
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              {projectSummaryReportList.map((projectSummaryReport) => (
                <ProjectSummaryReportCard
                  key={`project-summary-report-${projectSummaryReport.id}`}
                  projectSummaryReport={projectSummaryReport}
                  isChildPortfolio
                />
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  md: "flex-start",
                },
              }}
            >
              {projectSummaryReportList.map((projectSummaryReport) => (
                <ProjectSummaryReportCard
                  key={`project-summary-report-${projectSummaryReport.id}`}
                  projectSummaryReport={projectSummaryReport}
                  isChildPortfolio
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChildPortfolioProjectSummaryScreen;
