import { Box, Typography } from "@mui/material";

import Button from "components/Button/MaterialButton";
import { translation } from "constants/translation";
import BackButton from "components/BackButton";

type HeaderProps = {
  filledRequired: boolean;
  handleSubmit: () => void;
  isAssessment?: boolean;
};

const Header = ({
  filledRequired,
  handleSubmit,
  isAssessment = false,
}: HeaderProps) => {
  
  return (
    <Box
      sx={{
        height: "40px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <BackButton
        text={translation.back}
        variant="ttb"
        sx={{
          color: "cyan.500",
          fontSize: "14px",
          fontWeight: "500",
          pl: 0,
        }}
      />
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: "600",
          textAlign: "center",
          lineHeight: 1.3,
        }}
      >
        {isAssessment
          ? translation.create_assessment
          : translation.create_project}
      </Typography>
      <Button
        buttonText={translation.submit || "Submit"}
        sx={{
          color: "#fff",
          fontSize: "16px",
          fontWeight: "500",
          backgroundColor: "#91C6C5",
          lineHeight: 1.5,
          textTransform: "none",
        }}
        onClick={() => filledRequired && handleSubmit()}
        disabled={!filledRequired}
        arrowForward
      />
    </Box>
  );
};

export default Header;
