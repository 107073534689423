import { Box, Typography, Button, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";


export const ModalContainer = styled(Box)<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  transition: "all 300ms linear",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "300px" : "400px",
  backgroundColor: theme.palette.background.paper,
  boxShadow:
    "24px 24px 48px rgba(0, 0, 0, 0.12), 0 16px 24px rgba(0, 0, 0, 0.08)",
  padding: "16px",
  borderRadius: "8px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: "var(--Neutral-900, #323031)",
  fontSize: "18px",
  fontFamily: "Futura PT",
  fontWeight: 600,
  textAlign: "center",
  fontStyle: "normal",
  lineHeight: "24px",
  alignSelf: "stretch",
}));

export const Description = styled(Typography)({
  color: "var(--Neutral-900, #323031)",
  fontSize: "14px",
  fontFamily: "Futura PT",
  fontWeight: 400,
  textAlign: "center",
  fontStyle: "normal",
  lineHeight: "22px",
  alignSelf: "stretch",
});

export const ActionButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  gap: "12px",
});

export const CancellationButton = styled(Button)({
  borderRadius: "4px",
  border: "1px solid #5C9391",
  padding: "4px 10px",
  color: "#5C9391",
  flex: 1,
});

export const CancellationButtonText = styled(Typography)({
  color: "var(--Cyan-700, #5C9391)",
  fontSize: "14px",
  fontFamily: "Futura PT",
  fontWeight: 450,
  fontStyle: "normal",
  lineHeight: "22px",
});

export const ConfirmationButton = styled(Button)({
  borderRadius: "4px",
  backgroundColor: "var(--Cyan-500, #91C6C5)",
  padding: "4px 10px",
  flex: 1,
  "&:hover": {
    backgroundColor: "var(--Cyan-700, #5C9391)",
  },
});

export const ConfirmationButtonText = styled(Typography)({
  color: "var(--Neutral-50, #FFF)",
  fontSize: "14px",
  fontFamily: "Futura PT",
  fontWeight: 450,
  fontStyle: "normal",
  lineHeight: "22px",
});
