import { Box, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import useStore from "store/store";
import Image from "components/Image/Image";
import TTALogoImage from "images/tta-logo.png";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';

const FooterSection = () => {
  const {
    profileID,
    currLanguage,
  } = useStore((state) => state);
  return (
    <Box
      sx={{
        backgroundColor: "#91C6C5",
        padding: "24px 80px",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Image
            src={TTALogoImage}
            style={{
              width: "162px",
              display: "flex",
              height: 44,
              paddingLeft: 0,
              paddingRight: 0,
              objectFit: "cover"
            }}
            alt={TTALogoImage}
          />
        </Grid>

        <Grid
          item
          sm={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              flex: { xs: "1 1 100%", sm: "1 1 50%", md: "1 1 40%" },
              marginBottom: { xs: "16px", md: "0" },
            }}
          >
            <Typography variant="body1" sx={{ marginBottom: "8px" }}>
              <Link to="/#" style={{ textDecoration: "none", color: "inherit" }}>
                My lesson library
              </Link>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: "8px" }}>
              <a
                href="https://www.littlelab.com/about"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                About us
              </a>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: "8px" }}>
              <Link to={`profile/${profileID}`} style={{ textDecoration: "none", color: "inherit" }}>
                Profile
              </Link>
            </Typography>
            <Typography variant="body1">
              <Link to={`/${currLanguage}/settings`} style={{ textDecoration: "none", color: "inherit" }}>
                Setting
              </Link>
            </Typography>
          </Box>

          <Box
            sx={{
              flex: { xs: "1 1 100%", sm: "1 1 50%", md: "1 1 40%" },
              marginBottom: { xs: "16px", md: "0" },
            }}
          >
            <Typography variant="body1" sx={{ marginBottom: "8px" }}>
              <a
                href="https://www.littlelab.com/home/#teaching-toolbox"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Teacher tool box
              </a>
            </Typography>
            <Typography variant="body1">
              <a
                href="https://www.littlelab.com/home/#teacher-training"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Teacher training academy
              </a>
            </Typography>
          </Box>
          <Box
            sx={{
              flex: { xs: "1 1 100%", sm: "1 1 0%", md: "1 1 20%" },
              marginBottom: { xs: "16px", md: "0" },
            }}
          >

          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: { xs: "0px", md: "50px !important" },
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: "8px" }}>
            Trehaus Pte Ltd. All rights reserved
          </Typography>
          <Typography variant="body1" sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
            <LanguageOutlinedIcon fontSize="small" sx={{mr: "5px"}} />
              <a
                href="https://trehaus.co"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Trehaus.co
              </a>
          </Typography>
          <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
            <MailOutlinedIcon fontSize="small" sx={{mr: "5px"}} />support@trehaus.com
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterSection;
