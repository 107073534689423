import {styled} from '@mui/system'
import { Box } from '@mui/material'

export const ActivityActionWrapper = styled(Box)`
display:flex;
gap:16px;   
`


export const IconWrapper = styled(Box)`
display:flex;
gap:8px;
align-items:center;
`;