import React, { useEffect, useState } from "react";
import {
  Switch,
  Typography,
  Box,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import useStore from "store/store";
import Image from "components/Image/Image";

import {
  createChildAchievedMilestoneTracker,
  getChildAchievedMilestoneTracker,
  updateChildAchievedMilestoneTracker,
} from "api/portfolio-api";

import { useParams } from "react-router-dom";
import { API_Milestone } from "types/project";
import { API_Child } from "types/portfolio";
import { getAllFundamentals } from "api/fundamental-api";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";
import useSnack from "hooks/useSnack";
// import MilestonePhotoGalleryComponent from "./Screens/DevelopmentalGoalsScreenComponents/MilestonePhotoGalleryComponent";
import CreateObservationModal from "./Screens/DevelopmentalGoalsScreenComponents/CreateObservationModal";
import MilestonePhotoGalleryComponent from "./Screens/DevelopmentalGoalsScreenComponents/MilestonePhotoGalleryComponent";
import {
  DevGoalContainer,
  TextContainer,
  MileStoneHeadingContainer,
  Heading,
  MileStoneImageContainer,
  Detail,
  SwitchContainer,
  ImageContainer,
  MileStoneImageContainerSecondary
} from "./styles";

const DevelopmentalGoalPortfolio = () => {
  const { childID, h3TabSlug, devGoal } = useParams();
  const openSnack = useSnack();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { currLanguage, setIsRefreshLatestMilestone, setOpen } = useStore(
    (state) => ({
      setOpen: state.setIsPortfolioMiniDrawerOpen,
      currLanguage: state.currLanguage,
      setIsRefreshLatestMilestone: state.setIsRefreshLatestMilestone,
    })
  );

  const [childAchievedMilestoneTracker, setChildAchievedMilestoneTracker] =
    useState<API_Child.AchievedMilestoneTracker[]>([]);
  const [subFundamentals, setSubFundamentals] = useState<any>();
  const [relatedMilestones, setRelatedMilestones] = useState<API_Milestone[]>(
    []
  );
  const [developmentalGoal, setDevelopmentalGoal] = useState<any>();
  const [isCreateObservationModalOpen, setIsCreateObservationModalOpen] =
    useState<boolean>(false);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState<number>(-1);
  const handleCreateObservationModalClose = () => {
    setIsCreateObservationModalOpen(false);
  };
  const [openAccordion, setOpenAccordion] = useState(null);
  const [selectedSubFundamental, setSelectedSubFundamental] = useState(null);

  const handleMilestoneTrackerChange = async (
    tracker: API_Child.AchievedMilestoneTracker | null,
    milestoneId: number
  ) => {
    if (tracker) {
      const updatedTracker: API_Child.AchievedMilestoneTracker = JSON.parse(
        JSON.stringify(tracker)
      );
      updatedTracker.status = !updatedTracker.status;
      const res = await updateChildAchievedMilestoneTracker(updatedTracker);

      if (typeof res !== "string") {
        const trackerCopy = JSON.parse(
          JSON.stringify(childAchievedMilestoneTracker)
        );
        const index = childAchievedMilestoneTracker.findIndex((tracker_) => {
          return tracker_.id === res.id;
        });
        trackerCopy[index].status = res.status;
        setChildAchievedMilestoneTracker(trackerCopy);
        if (res.status) {
          openSnack("Attained!", true);
        } else {
          openSnack("Un-attained!", true);
        }
      }
    } else {
      const res = await createChildAchievedMilestoneTracker(
        Number(childID),
        milestoneId,
        null
      );
      if (typeof res !== "string") {
        setChildAchievedMilestoneTracker((prev) => [...prev, res]);
        openSnack("Attained!", true);
      }
    }

    setIsRefreshLatestMilestone(true);
  };

  useEffect(() => {
    const populateFundamentals = async () => {
      const res = await getAllFundamentals(
        currLanguage,
        h3TabSlug!,
        Number(childID)
      );

      res.sort((a, b) => b.priority - a.priority);
      if (typeof res !== "string") {
        if (devGoal) {
          const pageDevGoal = res.find((obj) => obj.slug === devGoal);

          if (pageDevGoal) {
            // SUB FUNDAMENTALS EXIST
            if (
              Array.isArray(pageDevGoal?.sub_fundamentals) &&
              pageDevGoal.sub_fundamentals.length > 0
            ) {
              setOpen(false);
              // CHECK IF SUBFUNDAMENTAL == ENGLISH OR MATH
              if (
                pageDevGoal.slug === "english-language-literacy" ||
                pageDevGoal.slug === "numeracy-math-concepts"
              ) {
                let groupedByFirstWord;

                groupedByFirstWord = pageDevGoal?.sub_fundamentals
                  .sort((a, b) => a.priority - b.priority)
                  .reduce((acc: any, obj: any) => {
                    let firstPart;

                    if (currLanguage == "en") {
                      const splitChar =
                        pageDevGoal.slug === "numeracy-&-math-concepts"
                          ? "-"
                          : " ";
                      // NUMBERS AND OPERATIONS
                      if (obj.names.en.split(" ")[0] == "Numbers") {
                        firstPart = obj.names.en
                          .split(" ")
                          .slice(0, 3)
                          .join(" ");
                        obj.name = obj.name
                          .split(splitChar)
                          .slice(3)
                          .join(" ")
                          .replace(/-/g, "");
                      }
                      // SHAPES DATA
                      else if (
                        obj.names.en.split(" ")[0] == "Shapes" ||
                        obj.names.en.split(" ")[0] == "Data"
                      ) {
                        firstPart = obj.names.en
                          .split(" ")
                          .slice(0, 4)
                          .join(" ");
                        obj.name = obj.name
                          .split(splitChar)
                          .slice(4)
                          .join(" ")
                          .replace(/-/g, "");
                      } else {
                        //  ALL ENGLISH SPLIT
                        firstPart = obj.name.split(splitChar)[0];
                        obj.name = obj.name
                          .split(splitChar)
                          .slice(1)
                          .join(" ")
                          .replace(/-/g, "");
                      }

                      if (!acc[firstPart]) {
                        acc[firstPart] = [];
                      }

                      acc[firstPart].push(obj);
                    } else if (currLanguage == "zh") {
                      //  ALL ENGLISH AND MATH SPLIT (THE SAME)
                      firstPart = obj.name
                        .split(/[-—]/)
                        .filter(Boolean)
                        .map((name: string) => name.trim());
                      if (!acc[firstPart[0]]) {
                        acc[firstPart[0]] = [];
                      }
                      obj.name = firstPart[1];
                      acc[firstPart[0]].push(obj);
                    }
                    return acc;
                  }, {});

                setSubFundamentals(groupedByFirstWord);
                setDevelopmentalGoal(
                  (Object.values(groupedByFirstWord)[0] as any)[0]
                );
                setRelatedMilestones(
                  (Object.values(groupedByFirstWord)[0] as any)[0].milestones
                );
              }

              // CHECK IF CHINESE
              else if (
                pageDevGoal.slug == "hua-yu-ting-shuo-yu-du-xie-neng-li"
              ) {
                const groupedByFirstWord =
                  pageDevGoal?.sub_fundamentals?.reduce(
                    (acc: any, obj: any) => {
                      const splitChar = "-";
                      const firstWord = obj.name.split(splitChar)[0];

                      if (!acc[firstWord]) {
                        acc[firstWord] = [];
                      }
                      // Remove the hyphen and join the remaining part of the name
                      obj.name = obj.name.split(splitChar).slice(1).join(" ");

                      acc[firstWord].push(obj);
                      return acc;
                    },
                    {}
                  );

                setSubFundamentals(groupedByFirstWord);
                setDevelopmentalGoal(pageDevGoal.sub_fundamentals[0]);
                setRelatedMilestones(
                  pageDevGoal.sub_fundamentals[0].milestones
                );
              } else {
                const subFundamentals = pageDevGoal.sub_fundamentals?.sort(
                  (a: any, b: any) => a.priority - b.priority
                );
                setSubFundamentals(subFundamentals);
                setDevelopmentalGoal(subFundamentals[0]);
                setRelatedMilestones(subFundamentals[0].milestones);
              }
            } else {
              setOpen(true);
              setDevelopmentalGoal(pageDevGoal);
              setRelatedMilestones(pageDevGoal.milestones);
            }
          }
        }
      } else {
        setRelatedMilestones([]);
      }
      setIsRefreshLatestMilestone(false);
    };

    if (devGoal && currLanguage && h3TabSlug && childID) {
      populateFundamentals();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devGoal, currLanguage, h3TabSlug, childID]);

  useEffect(() => {
    const populateChildAchievedMilestone = async () => {
      if (developmentalGoal) {
        const res = await getChildAchievedMilestoneTracker(
          currLanguage,
          Number(childID),
          developmentalGoal?.id
        );

        if (typeof res !== "string") {
          setChildAchievedMilestoneTracker(res);
        }
      }
    };

    if (childID && h3TabSlug && currLanguage && developmentalGoal) {
      populateChildAchievedMilestone();
    }
  }, [childID, h3TabSlug, currLanguage, developmentalGoal]);

  return (
    <>
      <CreateObservationModal
        open={isCreateObservationModalOpen}
        handleClose={handleCreateObservationModalClose}
        milestoneId={selectedMilestoneId}
      />
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: isMobile ? "column" : "row",
        }}
        onClick={() => {}}
      >
        {subFundamentals &&
          typeof subFundamentals === "object" &&
          (Array.isArray(subFundamentals)
            ? subFundamentals.length > 0
            : Object.keys(subFundamentals).length > 0) && (
            <Box
              sx={{
                width: isMobile ? "100%" : "20%",
                border: "1px solid #91C6C5",
                height: isMobile ? "20%" : "100%",
              }}
              onClick={() => {}}
            >
              {/* Drawer */}
              {devGoal === "english-language-literacy" ||
              devGoal === "hua-yu-ting-shuo-yu-du-xie-neng-li" ||
              devGoal === "numeracy-math-concepts"
                ? Object.keys(subFundamentals).map((key: any) => {
                    if (key in subFundamentals) {
                      if (
                        key == "Patterns" ||
                        key == "型式" ||
                        key == "偏旁部首" ||
                        key == "汉语拼音" ||
                        key == "笔画"
                      ) {
                        return (
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              padding: 2,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenAccordion(null);
                              setDevelopmentalGoal(subFundamentals[key][0]);
                              setRelatedMilestones(
                                subFundamentals[key][0].milestones
                              );
                            }}
                          >
                            {key}
                          </Typography>
                        );
                      } else {
                        return (
                          <Accordion
                            disableGutters
                            expanded={openAccordion === key}
                            onChange={() =>
                              setOpenAccordion(
                                openAccordion === key ? null : key
                              )
                            }
                            sx={{
                              borderBottom: "none !important",
                              "&:before": {
                                display: "none",
                              }, // This removes the divider line
                            }}
                            key={key}
                          >
                            <AccordionSummary
                              sx={{
                                borderBottom: "none !important",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                "& .MuiAccordionSummary-content": {
                                  margin: 0,
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  flex: 1,
                                }}
                              >
                                {key}
                              </Typography>
                              {openAccordion === key ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {subFundamentals[key].map(
                                  (subFundamental: any) => {
                                    return (
                                      <Box
                                        sx={{
                                          border: "1px solid #C4E4DF",
                                          borderRadius: "4px",
                                          p: "16px",
                                          backgroundColor:
                                            selectedSubFundamental ===
                                            subFundamental
                                              ? "#C4E4DF"
                                              : "white",
                                          "&:hover": {
                                            backgroundColor: "#C4E4DF",
                                            cursor: "pointer",
                                          },
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: "450",
                                          }}
                                          onClick={() => {
                                            setDevelopmentalGoal(
                                              subFundamental
                                            );
                                            setRelatedMilestones(
                                              subFundamental.milestones
                                            );
                                            setSelectedSubFundamental(
                                              subFundamental
                                            );
                                          }}
                                        >
                                          {subFundamental.name}
                                        </Typography>
                                      </Box>
                                    );
                                  }
                                )}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        );
                      }

                      // END PATTERNS
                    }
                  })
                : Array.isArray(subFundamentals) &&
                  subFundamentals.map((subFundamental: any) => {
                    return (
                      <Box
                        sx={{
                          borderBottom: "1px solid black",
                          backgroundColor:
                            selectedSubFundamental === subFundamental
                              ? "#C4E4DF"
                              : "white",
                          "&:hover": {
                            backgroundColor: "#C4E4DF",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => {
                          setRelatedMilestones(subFundamental.milestones);
                          setDevelopmentalGoal(subFundamental);
                          setSelectedSubFundamental(subFundamental);
                        }}
                      >
                        <Typography
                          sx={{
                            p: 2,
                            fontSize: "16px",
                            fontWeight: "700",
                          }}
                        >
                          {subFundamental.name}
                        </Typography>
                      </Box>
                    );
                  })}
            </Box>
          )}
        {isMobile ? (
          <Box
            sx={{
              border: "1px solid #91C6C5",
            }}
          >
            {relatedMilestones.map((milestone: any, id) => {
              const tracker = childAchievedMilestoneTracker.find((tracker) => {
                return tracker.milestone === milestone.id;
              });

              return (
                <DevelopmentalGoalMilestone
                  key={id}
                  milestone={milestone}
                  developmentalGoal={developmentalGoal}
                  handleMilestoneTrackerChange={handleMilestoneTrackerChange}
                  tracker={tracker}
                  setSelectedMilestoneId={setSelectedMilestoneId}
                  setIsCreateObservationModalOpen={
                    setIsCreateObservationModalOpen
                  }
                  isMobile={isMobile}
                />
              );
            })}
          </Box>
        ) : (
          <Box
            sx={{
              border: "1px solid #91C6C5",
              height: "100%",
              borderRadius: "8px",
              flex: 1,
              py: "16px",
              px: "32px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "20%" }}>
                <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                  Milestone Level
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1, minWidth: "72%" }}>
                <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                  Description
                </Typography>
              </Box>
              <Box
                sx={{ width: "15%", display: "flex", justifyContent: "center" }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                  Attained?
                </Typography>
              </Box>
            </Box>
            <Box sx={{}}>
              {relatedMilestones.map((milestone, id) => {
                const tracker = childAchievedMilestoneTracker.find(
                  (tracker) => {
                    return tracker.milestone === milestone.id;
                  }
                );

                return (
                  <DevelopmentalGoalMilestone
                    key={id}
                    milestone={milestone}
                    developmentalGoal={developmentalGoal}
                    handleMilestoneTrackerChange={handleMilestoneTrackerChange}
                    tracker={tracker}
                    setSelectedMilestoneId={setSelectedMilestoneId}
                    setIsCreateObservationModalOpen={
                      setIsCreateObservationModalOpen
                    }
                    isMobile={isMobile}
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DevelopmentalGoalPortfolio;

interface DevelopmentalGoalMilestoneProps {
  milestone: any;
  developmentalGoal: any;
  tracker: any;
  setSelectedMilestoneId: React.Dispatch<React.SetStateAction<number>>;
  setIsCreateObservationModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  handleMilestoneTrackerChange: (
    tracker: API_Child.AchievedMilestoneTracker | null,
    milestoneId: number
  ) => Promise<void>;
  isMobile: boolean;
}

const DevelopmentalGoalMilestone = ({
  milestone,
  developmentalGoal,
  tracker,
  setSelectedMilestoneId,
  setIsCreateObservationModalOpen,
  handleMilestoneTrackerChange,
  isMobile,
}: DevelopmentalGoalMilestoneProps) => {
  return (
    <>
      {isMobile ? (
        <>
          <DevGoalContainer>
            <MilestonePhotoGalleryComponent
              developmentalGoal={developmentalGoal}
              milestone={milestone}
              setIsCreateObservationModalOpen={setIsCreateObservationModalOpen}
              setSelectedMilestoneId={setSelectedMilestoneId}
            />
            <TextContainer>
              <MileStoneHeadingContainer>
                <Box>
                  <Heading>Milestone Level</Heading>
                </Box>
                <Box>
                  <Heading>Attained</Heading>
                </Box>
              </MileStoneHeadingContainer>

              <MileStoneImageContainer>
                <MileStoneImageContainerSecondary
                >
                  <ImageContainer
                    sx={{
                      backgroundColor:
                        milestone.age == "Sprouts"
                          ? "#F3EBE4"
                          : milestone.age == "Buds"
                          ? "#91C6C5"
                          : "#F9DF60",
                    }}
                  >
                    <Image
                      style={{ width: "20px", height: "20px" }}
                      src={
                        milestone.age == "Sprouts"
                          ? SproutsIcon
                          : milestone.age == "Buds"
                          ? BudsIcon
                          : BlossomsIcon
                      }
                      alt="Level"
                    />
                  </ImageContainer>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Detail>{milestone.age} level</Detail>
                  </Box>
                </MileStoneImageContainerSecondary>
                <SwitchContainer>
                  <Heading>
                    <Switch
                      inputProps={{ "aria-label": "controlled" }}
                      checked={tracker ? tracker.status : false}
                      onChange={() => {
                        handleMilestoneTrackerChange(
                          tracker ? tracker : null,
                          Number(milestone.id)
                        );
                      }}
                    />
                  </Heading>
                </SwitchContainer>
              </MileStoneImageContainer>

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Heading>Description</Heading>
                <Detail>{milestone.name}</Detail>
              </Box>
            </TextContainer>
          </DevGoalContainer>
        </>
      ) : (
        <Box sx={{ display: "flex", mb: 2 }} onClick={() => {}}>
          <Divider />
          <Box
            sx={{
              width: "20%",
              display: "flex",
              gap: 1,
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "32px",
                  height: "32px",
                  overflow: "hidden",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    milestone.age == "Sprouts"
                      ? "#F3EBE4"
                      : milestone.age == "Buds"
                      ? "#91C6C5"
                      : "#F9DF60",
                }}
              >
                <Image
                  style={{ width: "20px", height: "20px" }}
                  src={
                    milestone.age == "Sprouts"
                      ? SproutsIcon
                      : milestone.age == "Buds"
                      ? BudsIcon
                      : BlossomsIcon
                  }
                  alt="Level"
                />
              </Box>
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                {milestone.age} level
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              minWidth: "72%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
              {milestone.name}
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <MilestonePhotoGalleryComponent
                developmentalGoal={developmentalGoal}
                milestone={milestone}
                setIsCreateObservationModalOpen={
                  setIsCreateObservationModalOpen
                }
                setSelectedMilestoneId={setSelectedMilestoneId}
              />
            </Box>
          </Box>
          <Box sx={{ width: "15%", display: "flex", justifyContent: "center" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
              <Switch
                inputProps={{ "aria-label": "controlled" }}
                checked={tracker ? tracker.status : false}
                onChange={() => {
                  handleMilestoneTrackerChange(
                    tracker ? tracker : null,
                    Number(milestone.id)
                  );
                }}
              />
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
