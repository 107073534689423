import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const ModalContainer = styled(Box)<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    width: isMobile ? "343px" : "724px",
    maxHeight: "604px",
    position: "absolute",
    backgroundColor: "white",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "16px",
    borderRadius: "10px",
    overflow: "hidden",
    zIndex: 100,
    "&:focus-visible": {
      outline: "none",
    },
  })
);

export const HeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const HeadingStyle = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  font-family: "Futura PT", sans-serif;
  color: #323031;
`;

export const ImageBox = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  padding: 0 16px;
  overflow-y: auto;
`;

export const ImageContainer = styled(Box)<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    position: "relative",
    width: isMobile ? "85px" : "120px",
    height: isMobile ? "85px" : "120px",
    overflow: "hidden",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 1)",
    },
    "&:hover img": {
      opacity: 0.3,
    },
    "&:hover div": {
      opacity: 1,
    },
  })
);

export const ImageCaption = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
`;
