import {
  Switch,
  Typography,
  Box,

} from "@mui/material";
import {styled} from '@mui/system'

export const DevGoalContainer= styled(Box)({
  display: "flex",
  width: "343px",
  paddingBottom: "12px",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
  background: "var(--Neutral-50, #FFF)",
  paddingLeft:'7px',
  paddingRight:'5px'
})

export const TextContainer= styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
})

export const MileStoneHeadingContainer =styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
})

export const MileStoneImageContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
})
export const MileStoneImageContainerSecondary = styled(Box)({
  display: "flex",
  gap: 2,
  alignItems: "center",
})

export const ImageContainer = styled(Box)({
  width: "32px",
  height: "32px",
  overflow: "hidden",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

export const Heading =styled(Typography)({
  fontSize: "12px", 
  fontWeight: "700"
})
export const Detail =styled(Typography)({
  fontSize: "12px", 
  fontWeight: "400"
})

export const SwitchContainer= styled(Box)({
  width: "10%",
  display: "flex",
  justifyContent: "center",
})

