import { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Typography,
  Stack,
  TextField,
  Autocomplete,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";

import Button from "components/Button/Button";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

import { API_Update_Child, API_Profile_Short, API_Child } from "types/profile";

import { translation } from "constants/translation";
import ImageForm from "containers/CreateProfilePage/ImageForm";
import { TEXT_FIELD_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";
import {
  getChildProfile,
  getChildRegisterParentProfile,
} from "api/profile-api";
import { UpdateProfile } from "types/auth";
import { updateProfile } from "api/auth-api";
import Logo from "images/little-lab-logo.png";
import { DATE_FORMAT } from "./PhotoAlbumScreenComponents/PhotoAlbumComponents";
import moment from "moment";
import { isProfileInCreateGroupRoleList } from "helper/helper";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { API_Group_Full } from "types/group";
import Image from "components/Image/Image";
import { CURLANG } from "constants/url";
import { PROFILE_PAGE } from "constants/url";
import ImageFrame from "images/children-portfolio/rectangle-frame.png";
import EditIcon from "@mui/icons-material/Edit";

const ChildProfileScreen = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const { childID } = useParams();
  const openSnack = useSnack();
  const { profileDetails, groupList, currLanguage } = useStore((state) => ({
    groupList: state.groupList,
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const initialFormData = {
    id: -1,
    full_name: "",
    preferred_name: "",
    dob: "",
    image: "",
  };
  const [formData, setFormData] = useState<API_Update_Child>(initialFormData);
  const [oldFormData, setOldFormData] =
    useState<API_Update_Child>(initialFormData);
  const [childGroupList, setChildGroupList] = useState<number[]>([]);
  const [parentList, setParentList] = useState<API_Profile_Short[]>([]);
  const today = new Date().toLocaleDateString("en-ca");

  const childGroups: API_Group_Full[] = groupList.filter((group) =>
    childGroupList.includes(group.id)
  );

  const teachers: API_Profile_Short[] = Array.from(
    new Map(
      childGroups
        .flatMap((group) => group.teachers)
        .map((teacher) => [teacher["id"], teacher])
    ).values()
  );

  useEffect(() => {
    const populateData = async () => {
      const childDetailsRes = await getChildProfile(
        childID!,
        localStorage.getItem("access")
      );
      if (typeof childDetailsRes !== "string") {
        const data = {
          id: childDetailsRes.id,
          full_name: childDetailsRes.full_name ? childDetailsRes.full_name : "",
          preferred_name: childDetailsRes.preferred_name,
          dob: childDetailsRes.dob,
          image: childDetailsRes.image,
          parent: childDetailsRes.parent ? childDetailsRes.parent : -1,
        };
        setChildGroupList(
          childDetailsRes.groups
            ? childDetailsRes.groups.map((group) => group.id)
            : []
        );
        setFormData(data);
        setOldFormData(data);
        const res = await getChildRegisterParentProfile(
          childDetailsRes.school!
        );
        setParentList([
          ...res,
          {
            id: -1,
            image: "",
            preferred_name: "No Parent",
            full_name: "No Parent",
            dob: "",
          },
        ]);
      }
    };
    populateData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childID]);

  const handleUpdate = async () => {
    const formDataCopy = JSON.parse(JSON.stringify(formData));

    if (typeof formData.image === "string") {
      //don't send if is string
      delete formDataCopy.image;
    } else {
      formDataCopy.image = formData.image;
    }

    const res: API_Child | string = await updateProfile(
      childID!,
      formDataCopy as UpdateProfile,
      localStorage.getItem("access")
    );
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(translation.updateSuccess, true);
      setOldFormData({ ...formData, image: res.image });
    }
    return;
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {isProfileInCreateGroupRoleList(profileDetails.role) && (
        <Box
          sx={{ position: "absolute", right: isXs ? 6 : 8, top: isXs ? 6 : 8 }}
        >
          <Box sx={{ display: "flex", gap: 2, flexDirection: "row-reverse" }}>
            <Button
              buttonText={
                isEditMode ? translation.saveChanges || "Save Changes" : ""
              }
              onClick={() => {
                if (isEditMode) {
                  setIsEditMode(false);
                  handleUpdate();
                } else {
                  setIsEditMode(true);
                }
              }}
              arrow={false}
              style={{
                fontSize: isXs ? 8 : 16,
                padding: isXs ? "5px 6px" : "10px 12px",
                display: isEditMode ? "flex" : "none",
              }}
            />
            {!isEditMode && (
              <IconButton
                onClick={() => {
                  if (isEditMode) {
                    setIsEditMode(false);
                    handleUpdate();
                  } else {
                    setIsEditMode(true);
                  }
                }}
              >
                <EditIcon />
              </IconButton>
            )}

            <Button
              buttonText={translation.discardEdits || "Discard Edits"}
              onClick={() => {
                if (isEditMode) {
                  setIsEditMode(false);
                  setFormData(oldFormData);
                }
              }}
              arrow={false}
              btnType="outlined"
              style={{
                display: isEditMode ? "flex" : "none",
                fontSize: isXs ? 8 : 16,
                padding: isXs ? "4px 5px" : "10px 12px",
              }}
            />
          </Box>
        </Box>
      )}

      {isEditMode ? (
        <Stack
          component="form"
          sx={{
            margin: "auto",
            my: isXs ? "50px" : "16px",
            alignItems: "center",
          }}
          spacing={1}
          noValidate
          autoComplete="off"
        >
          <ImageForm
            setFormData={setFormData}
            role={"CH"}
            image={
              typeof formData.image === "string"
                ? formData.image
                : typeof formData.image === "object"
                ? formData.image !== null
                  ? URL.createObjectURL(formData.image)
                  : ""
                : ""
            }
          />
          {`Profile Image`}
          <Stack spacing={1} direction={isXs ? "column" : "row"}>
            <TextField
              required
              sx={TEXT_FIELD_STYLE}
              label={translation.full_name}
              value={formData.full_name}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  full_name: event.target.value,
                }));
              }}
            />
            <TextField
              sx={TEXT_FIELD_STYLE}
              required
              label={translation.preferred_name}
              value={formData.preferred_name}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  preferred_name: event.target.value,
                }));
              }}
            />
          </Stack>
          <TextField
            required
            sx={TEXT_FIELD_STYLE}
            label={translation.dob}
            type="date"
            value={formData.dob}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setFormData((prev) => ({ ...prev, dob: event.target.value }));
            }}
            inputProps={{
              max: today,
            }}
          />
          <Box sx={{ display: "flex" }}>
            <Autocomplete
              key={parentList.map((parent) => parent.id).join("-")}
              sx={TEXT_FIELD_STYLE}
              disablePortal
              id="combo-box-parent"
              onChange={(
                event: any,
                parent_updated: API_Profile_Short | null
              ) => {
                if (parent_updated !== null) {
                  setFormData((prev) => ({
                    ...prev,
                    parent: parent_updated.id,
                  }));
                }
              }}
              options={parentList}
              getOptionLabel={(parent) => parent.preferred_name}
              // renderOption={(props, option) => option.name}
              value={parentList.find((parent) => parent.id === formData.parent)}
              isOptionEqualToValue={(option, value) =>
                option.preferred_name === value.preferred_name
              }
              renderInput={(params) => {
                return <TextField {...params} label="Parents" placeholder="" />;
              }}
            />
          </Box>
        </Stack>
      ) : oldFormData.id === -1 ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              padding: { xs: 0, sm: 2, md: 4 },
              alignItems: "center",
              gap: 4,
              flexDirection: isXs ? "column" : "row",
              borderRadius: "10px",
              backgroundColor: "#F0F0F0",
            }}
          >
            <Box
              sx={{
                mt: 4,
                mb: 1,
                width: { xs: 100, sm: 150 },
                height: { xs: 140, sm: 150 },
                position: "relative",
              }}
            >
              <Image
                src={oldFormData.image as string}
                alt={oldFormData.preferred_name}
                style={{
                  position: "absolute",
                  top: isXs ? "2px" : "-3px",
                  left: isXs ? "-4px" : "",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                  objectFit: "cover",
                }}
              />
              <Image
                src={ImageFrame}
                alt=""
                style={{
                  zIndex: 1,
                  position: "absolute",
                  left: isXs ? "" : "10px",
                  top: isXs ? "" : "-12px",
                  transform: isXs ? "scale(1.8, 1.5)" : "scale(1.7, 1.45)",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  pointerEvents: "none",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                textAlign: isXs ? "center" : "left",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: 20, sm: 32 },
                  whiteSpace: "normal",
                }}
              >
                {oldFormData.full_name} ({oldFormData.preferred_name})
              </Typography>

              {childGroups.length > 0 && (
                <Typography sx={{ fontSize: { xs: 14, sm: 20 } }}>
                  {translation.group}:{" "}
                  <Typography
                    component="span"
                    sx={{ fontSize: { xs: 14, sm: 20 }, fontWeight: 500 }}
                  >
                    {childGroups.map((group) => group.name).join(", ")}
                  </Typography>
                </Typography>
              )}

              <Typography sx={{ fontSize: { xs: 14, sm: 20 } }}>
                {translation.bornOn}:{" "}
                <Typography
                  component="span"
                  sx={{ fontSize: { xs: 14, sm: 20 }, fontWeight: 500 }}
                >
                  {moment(oldFormData.dob).format(DATE_FORMAT)}
                </Typography>
              </Typography>

              {parentList.length !== 0 && oldFormData.parent !== -1 && (
                <Typography sx={{ fontSize: { xs: 14, sm: 20 } }}>
                  {translation.parent}:{" "}
                  <Typography
                    component="span"
                    sx={{ fontSize: { xs: 14, sm: 20 }, fontWeight: 500 }}
                  >
                    {
                      parentList.find(
                        (parent) => parent.id === oldFormData.parent
                      )?.preferred_name
                    }
                  </Typography>
                </Typography>
              )}
            </Box>
          </Box>
          {teachers.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
              <Typography sx={{ fontSize: "1.125rem", fontWeight: 500, my: 3 }}>
                {translation.teachers}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                {teachers.map((teacher, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: {
                        xs: "35vw",
                        lg: "200px",
                      },
                      margin: "0 2vw",
                      height: isLg ? "300px" : "",
                    }}
                    onClick={() => {
                      navigate(
                        `${CURLANG(currLanguage)}/${PROFILE_PAGE(
                          teacher.id.toString()
                        )}`
                      );
                    }}
                  >
                    <Image
                      src={teacher.image ? teacher.image : Logo}
                      alt={teacher.preferred_name}
                      style={{
                        height: isLg ? "200px" : "35vw",
                        width: "100%",
                        objectFit: "contain",
                        borderRadius: "10px",
                        display: "flex",
                        // marginBottom: "8px",
                      }}
                    />

                    <Typography>{teacher.preferred_name}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ChildProfileScreen;
