import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../../store/store";
import { LESSON_ACTIVITY_PAGE } from "../../../constants/url";

import axios from "axios";
import moment from "moment";
import { getProjectCategories } from "api/project-api";
import { getAllSchoolForRegister } from "api/school-api";
import { getTimetableEventList } from "api/timetable-api";
import {  getAllTeacherProfile, getAllChildProfile,  getCurrentNextGroupLessonsInfo, getScheduleByGroup } from "api/profile-api";

import { API_School_Simple } from "types/school";
import { ProjectCategoryModule, ProjectCategory } from "types/project";
import { API_Group_Admin_View_Lesson_Info, API_Group_Lesson_Info } from "types/profile";

import { translation } from "constants/translation";
import { CURLANG, TEACHER_TRAINING_ACADEMY, CREATE_GROUP_PAGE, MARKETPLACE } from "constants/url";

import DrawerButton from "./DrawerButton";
import ParentDashboard from "./ParentDashboard";
import TermsAndConditions from "./TermsAndConditions";
import Person from "./DashboardComponents/Person";
import WalkthroughSteps from "containers/WalkthroughSteps";
import MobileDashboard from "./DashboardComponents/MobileDashboard";
import QuickItems from "./TeacherDashboardComponent/QuickItems";
import TeacherDashboardAttendanceModal from "./TeacherDashboardAttendanceModal";
import noAssignedClasses from "images/noAssignedClasses.svg"
import noLessonFound from "images/noLessonFound.svg"


import {
  Box,
  Button,
  Typography,
  Avatar,
  Divider,
  FormControl,
  IconButton,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import useColors from "hooks/useColors";
import Image from "components/Image/Image";
import Logo from "images/logo-2-colour.png";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EdtechLandingPageImage from "images/trehaus-color-half-opacity.png";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const Dashboard = () => {

  const theme = useTheme();
  const colors = useColors();
  const navigate = useNavigate();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  const { role, profileDetails, currLanguage, groupList, currGroup, groupProjectBoxProps } = useStore(
    (state) => ({
      ...state,
      role: state.profileDetails.role,
      profileDetails: state.profileDetails,
      currLanguage: state.currLanguage,
      refreshGroup: state.refreshGroup,
      groupList: state.groupList,
      currGroup: state.currGroup,
      groupProjectBoxProps: state.groupProjectBoxProps,
    })
  );

  const dateConversion = (date: string) => {
    const dateToConvert = new Date(date);
    const { day, month, year } = {
      day: dateToConvert.getDate(),
      month: dateToConvert.getMonth(),
      year: dateToConvert.getFullYear()
    };
    const monthName = monthNames[month];
    const formattedDate = `${day} ${monthName} ${year}`;
    return formattedDate;
  };

  const initialGroupLessonInfo: API_Group_Lesson_Info = {
    id: -1,
    name: "",
    slug: "",
    image: '',
    school: -1,
    next_lessons: [],
    current_lessons: [],
    completed_projects: []
  };

  const getProjectBoxes = () => {
    if (Object.keys(groupProjectBoxProps).length === 0) {
      return [];
    }
    const projects: any[] = groupProjectBoxProps[currGroup.id.toString()];
    if (projects) {
      projects?.sort((a: any, b: any) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime());
      projects?.forEach((project: any) => {
        project.startAtDisplay = dateConversion(project.startAt);
        project.endAtDisplay = dateConversion(project.endAt);
      });
      return projects;
    }
  };


  const today = moment();
  const dayOfWeek = today.isoWeekday();
  const lessonOptions = ["Ongoing", "Upcoming", "Completed"];
  const isSmallScreen = window.matchMedia('(max-width: 1450px)').matches;



  const totalnotemessageRef = useRef<any>(null);
  const timetableRef = useRef<any>(null);
  const quickItemsRef = useRef<HTMLDivElement>(null);

  const [allEvents, setAllEvents] = useState<any>();
  const [lessons, setLessons] = useState("Ongoing");
  const [modules, setModules] = useState<string[]>([]);
  const [schoolId, setSchoolId] = useState<number>(-1);
  const [maximumHeight, setMaximumHeight] = useState(0);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [classesMainHeight, setClassesMainHeight] = useState(0);
  const [schoolList, setSchoolList] = useState<API_School_Simple[]>();
  const [timetableClass, setTimetableClass] = useState<any>("All Classes");
  const [totalTeachersLength, setTotalTeachersLength] = useState<number>(0);
  const [totalChildrenLength, setTotalChildrenLength] = useState<number>(0);
  const [projects, setProjects] = useState<any[] | undefined>(getProjectBoxes());
  const [attendanceModalOpen, setAttendanceModalOpen] = useState<boolean>(false);
  const [isQuickItemsExpanded, setIsQuickItemsExpanded] = useState<boolean>(true);
  const [groupLessonInfo, setGroupLessonInfo] =
    useState<API_Group_Lesson_Info>(initialGroupLessonInfo);
    


  useEffect(() => {
    if (role === "TTACE") {
      navigate(`${CURLANG(currLanguage)}/${TEACHER_TRAINING_ACADEMY}`);
      return;
    }
    if (role !== "WAD") {
      setSchoolId(profileDetails.school ? profileDetails.school.id : -1);
      return;
    }
    const getAllSchools = async () => {
      const res = await getAllSchoolForRegister();
      setSchoolList(res);
      setSchoolId(res[0].id);
    };
    getAllSchools();
  }, [role]);


  useEffect(() => {
    const populateData = async () => {
      const teacherListRes = await getAllTeacherProfile();
      const childrenListRes = await getAllChildProfile();
      setTotalTeachersLength(teacherListRes.length);
      setTotalChildrenLength(childrenListRes.length);
    };
    populateData();
  }, []);


  useEffect(() => {
    const source = axios.CancelToken.source();

    const populateAllLessonsInfo = async () => {
      const res = await getCurrentNextGroupLessonsInfo(
        currLanguage,
        currGroup.id,
        localStorage.getItem("access"),
        source,
      );

      if (typeof res !== "string") {
        setGroupLessonInfo(res)
      }
    };

    const populateCompletedProjects = async () => {
      const res = await getScheduleByGroup(
        currLanguage,
        currGroup.id,
        localStorage.getItem("token")
      )
      if (typeof res !== "string") {
        if (res.detail == 'No Record') {
          return
        }
        else {
          let completed = res.filter((project: any) => project.current_lesson == null && project.next_lesson == null).map((project: any) => project.project.name)
          setGroupLessonInfo((prevState:any) => ({
            ...prevState,
            completed_projects: completed
          }));
        }
      }
    }

    setGroupLessonInfo(initialGroupLessonInfo);
    const fetchAndPopulateData = async () => {
      try {
        if (!currLanguage || currGroup.id === -1 || groupLessonInfo.id === currGroup.id) {
          return;
        }
        await populateAllLessonsInfo();

        if (groupLessonInfo.id) {
          await populateCompletedProjects();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAndPopulateData()

    return () => {
      source.cancel();
    };
  }, [currLanguage, currGroup.id]);



  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const allEvents = await getTimetableEventList(currLanguage, profileDetails.id.toString());

        const events = allEvents.filter((event) => {
          return event.day === dayOfWeek && event.currGroup !== 0;
        });
        const groupedEvents = events.reduce((acc: any, event: any) => {
          const key: any = `${event.start} - ${event.end}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(event);
          return acc;
        }, {});

        setAllEvents(groupedEvents);

        setIsNotFound(false);
      } catch (error) {
        setIsNotFound(true);
      }
    };

    const fetchModules = async () => {
      const allModules: ProjectCategoryModule[] = (await getProjectCategories(currLanguage)).reduce(
        (arr: ProjectCategoryModule[], curr: ProjectCategory) => [...arr, ...curr.modules],
        []
      );

      setModules(allModules.map((module) => module.name));
    };

    fetchEvents();
    fetchModules();
  }, [currLanguage, profileDetails.id.toString(), lessons]);

  useEffect(() => {
    setAllEvents(allEvents);
  }, [allEvents]);



  useEffect(() => {
    const getHeight = (ref: any) => {
      if (ref.current) {
        const { offsetHeight, clientTop, clientLeft } = ref.current;
        const { marginTop: marginTop1, marginBottom: marginBottom1 } = window.getComputedStyle(ref.current);
        const totalHeight =
          offsetHeight + clientTop + clientLeft + parseFloat(marginTop1) + parseFloat(marginBottom1);
        return totalHeight;
      }
    };
    if (profileDetails.role != "TR") {
      const newHeight = getHeight(timetableRef) - getHeight(totalnotemessageRef) - 16;
      setClassesMainHeight(newHeight);
    } else if (profileDetails.role == "TR") {
      const newHeight = getHeight(timetableRef) - getHeight(quickItemsRef) - 16;
      setClassesMainHeight(newHeight);
    }
    setMaximumHeight(getHeight(timetableRef));
  }, [profileDetails]);

  useEffect(() => {
    setProjects(getProjectBoxes());
  }, [groupProjectBoxProps, currGroup]);



  return (
    <>
      {profileDetails.role == "TR" && attendanceModalOpen && (
        <TeacherDashboardAttendanceModal
          open={attendanceModalOpen}
          handleClose={() => {
            setAttendanceModalOpen(false);
          }}
        />
      )}

      {profileDetails.user != -1 && !profileDetails.terms_agreed && <TermsAndConditions />}

      {isLg ? (
        <MobileDashboard setAttendanceModalOpen={setAttendanceModalOpen} />
      ) : (
        !profileDetails.role ?
          <LoadingIndicator />
          : <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                //total height
                height: {
                  xs: "calc(100vh - 56px)",
                  sm: "calc(100vh - 64px)",
                },
                backgroundColor: "#EBF8F8",
              }}
              
            >
              <Image
                src={EdtechLandingPageImage}
                alt="background"
                objectFit="cover"
                style={{
                  opacity: 0.5,
                  position: "fixed",
                  top: 0,
                  left: 0,
                  zIndex: -1,
                }}
              />

              {profileDetails.role === "PR" ? (
                <ParentDashboard />
              ) : (
                // new box
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    my: "32px",
                    px: "24px",
                    gap: "16px",
                  }}
                >
                  {/* TOTAL, NOTES, MESSAGE, MY CLASSES */}
                  <Box
                    sx={{
                      width: "75%",
                      display: "flex",
                      height: "100%",
                      gap: "16px",
                      flexDirection: "column",
                      minHeight: maximumHeight,
                      maxHeight: maximumHeight,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        gap: "16px",
                      }}
                      ref={totalnotemessageRef}
                    >
                      <QuickItems
                        ref={quickItemsRef}
                        isExpanded={isQuickItemsExpanded}
                        setIsExpanded={setIsQuickItemsExpanded}
                      />
                    </Box>
                    {/* classes, currently on, teachers*/}

                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        flex: 1,
                        height: classesMainHeight,
                        minHeight: classesMainHeight,
                        maxHeight: isQuickItemsExpanded ? classesMainHeight : "auto",
                      }}
                    >
                      {/* START OF CLASSES */}

                      <Box
                        sx={{
                          flex: "0 0 20%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "space-between",
                          justifyContent: "flex-start",
                          backgroundColor: "#F8FDFD",
                          height: classesMainHeight,
                          minHeight: classesMainHeight,
                          maxHeight: isQuickItemsExpanded ? classesMainHeight : "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              mt: "8px",
                              ml: "8px",
                              fontWeight: "450",
                              fontSizex: "16px",
                            }}
                          >
                            {translation.myClasses}
                          </Typography>
                          {profileDetails.role != "TR" && (
                            <IconButton
                              onClick={() =>
                                navigate(`${CURLANG(currLanguage)}/${CREATE_GROUP_PAGE}`, {
                                  state: schoolId !== -1 ? { schoolId } : null,
                                })
                              }
                              id="admin-add-class"
                            >
                              <AddIcon sx={{ width: "24px", height: "24px" }} />
                            </IconButton>
                          )}
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            pl: 2,
                            pb: 1,
                            overflowY: "auto",
                            "&::-webkit-scrollbar": {
                              width: "5px",
                            },
                          }}
                        >
                          {groupList.map((group, index) => (
                            <Box key={index}>
                              <DrawerButton group={group} index={index} />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                      {/* END OF CLASSES */}
                      {
                        groupList.length > 0 ? <Box
                          sx={{
                            display: "flex",
                            flex: "0 0 80%",
                            p: "12px",
                            gap: "16px",
                          }}
                        >
                          {/* START OF CURRENTLY ON */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {lessonOptions.map((lesson) => {
                                return (
                                  <Button
                                    sx={{
                                      borderBottom: lessons == lesson ? "2px #91C6C5 solid" : "none",
                                      borderRadius: 0,
                                      m: 0,
                                      minWidth: "160px",
                                      px: "16px",
                                    }}
                                    onClick={() => {
                                      setLessons(lesson);
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "450",
                                        textTransform: "none",
                                        color: lessons == lesson ? "#5C9391" : "#8C8C8C",
                                      }}
                                    >
                                      {lesson} {lesson == "Completed" ? "Projects" : "Lessons"}
                                    </Typography>
                                  </Button>
                                );
                              })}
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                overflowY:
                                  groupLessonInfo.id == -1 ? "hidden" : groupLessonInfo.next_lessons.length == 0 ? "hidden" : "auto",
                                "&::-webkit-scrollbar": {
                                  width: "5px",
                                },
                                height: '100%',
                              }}
                            >
                              {lessons == "Ongoing" &&
                                (groupLessonInfo.id === -1 ? (
                                  <LoadingIndicator />
                                ) : groupLessonInfo.id !== -1 && groupLessonInfo.current_lessons.length > 0 ? (
                                  groupLessonInfo.current_lessons.map((lesson: any, index: any) => {
                                    const evenColor = "#F8FDFD";
                                    const oddColor = "#FFFFEF";
                                    const activitySlug =
                                      lesson.activities.length > 0 ? `activity/${lesson.activities[0].id}` : "";

                                    return (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          border: "1px solid #91C6C5",
                                          borderRadius: "4px",
                                          mt: "8px",
                                          mr: "8px",
                                          cursor: "pointer",
                                          backgroundColor: index % 2 == 0 ? evenColor : oddColor,
                                        }}
                                        key={index}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            py: "12px",
                                            pr: "8px",
                                            pl: "12px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "16px",
                                              fontWeight: "450",
                                            }}
                                          >
                                            {lesson.project_name}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Lesson {(lesson?.total_completed_lessons ?? 0) + 1}: {lesson.name}
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                            justifyContent: "space-between",
                                            px: "8px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "10px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {lesson?.total_completed_lessons ?? 0}/{lesson?.total_lessons} Lesson
                                            Conducted
                                          </Typography>
                                          <Button
                                            onClick={() => {
                                              navigate(
                                                `${CURLANG(currLanguage)}/${LESSON_ACTIVITY_PAGE(
                                                  lesson.project_slug,
                                                  lesson.id.toString()
                                                )}/${activitySlug}`
                                              );
                                            }}
                                            sx={{
                                              width: "43px",
                                              py: "4px",
                                              color: "#5C9391",
                                              border: "1px solid #5C9391",
                                              borderRadius: "4px",
                                              textTransform: "none",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                fontWeight: "450",
                                              }}
                                            >
                                              Resume
                                            </Typography>
                                          </Button>
                                        </Box>
                                      </Box>
                                    );
                                  })
                                ) : (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      height: '100%',

                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: 1
                                    }}
                                  >
                                    <Box sx={{ height: "150px", width: "150px" }}>
                                      <Image src={noLessonFound} alt="No Lessons Found" objectFit="contain" />

                                    </Box>
                                    <Typography sx={{ fontSize: "16px", fontWeight: '400px', color: '#8C8C8C' }}>
                                      No Lesson Found</Typography>
                                    <Button sx={{ borderRadius: '4px', border: "1px solid #5C9391", color: "#5C9391", textTransform: "none" }} variant="outlined" onClick={() => {
                                      navigate(`/${currLanguage}/${MARKETPLACE}`)
                                    }}>
                                      <Typography sx={{ fontSize: '16px', fontWeight: '450' }}>Go to Lesson Plan Library</Typography>
                                    </Button>
                                  </Box>
                                ))}
                              {lessons === "Upcoming" &&
                                (groupLessonInfo.id === -1 && groupLessonInfo.next_lessons.length > 0 ? (
                                  <LoadingIndicator />
                                ) : groupLessonInfo.id !== -1 && groupLessonInfo.next_lessons.length > 0 ? (
                                  groupLessonInfo.next_lessons.map((lesson: any, index: any) => {
                                    const evenColor = "#F8FDFD";
                                    const oddColor = "#FFFFEF";
                                    const activitySlug =
                                      lesson.activities.length > 0 ? `activity/${lesson.activities[0].id}` : "";

                                    if ((lesson.total_completed_lessons + 2) <= lesson.total_lessons) {
                                      return (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            border: "1px solid #91C6C5",
                                            borderRadius: "4px",
                                            mt: "8px",
                                            mr: "8px",
                                            cursor: "pointer",
                                            backgroundColor: index % 2 == 0 ? evenColor : oddColor,
                                          }}
                                          key={index}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              py: "12px",
                                              pr: "8px",
                                              pl: "12px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "16px",
                                                fontWeight: "450",
                                              }}
                                            >
                                              {lesson.project_name}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              Lesson {(lesson?.total_completed_lessons ?? 0) + 2}: {lesson.name}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      );
                                    }
                                  })
                                ) : (
                                  <Box
                                  sx={{
                                    width: '100%',
                                    height: '100%',

                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 1
                                  }}
                                >
                                  <Box sx={{ height: "150px", width: "150px" }}>
                                    <Image src={noLessonFound} alt="No Lessons Found" objectFit="contain" />

                                  </Box>
                                  <Typography sx={{ fontSize: "16px", fontWeight: '400px', color: '#8C8C8C' }}>
                                    No Upcoming Lessons</Typography>
                                  <Button sx={{ borderRadius: '4px', border: "1px solid #5C9391", color: "#5C9391", textTransform: "none" }} variant="outlined" onClick={() => {
                                    navigate(`/${currLanguage}/${MARKETPLACE}`)
                                  }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '450' }}>Go to Lesson Plan Library</Typography>
                                  </Button>
                                </Box>
                                ))
                                }
                                {
                                  lessons === "Completed" && 
                                  (groupLessonInfo.id === -1  ? (
                                    <LoadingIndicator />
                                  ) : groupLessonInfo.id !== -1 && groupLessonInfo.completed_projects && groupLessonInfo.completed_projects.length > 0 ? (
                                    groupLessonInfo.completed_projects.map((project: any, index: any) => {
                                      
                                      const evenColor = "#F8FDFD";
                                      const oddColor = "#FFFFEF";
                                      
                                        return (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              border: "1px solid #91C6C5",
                                              borderRadius: "4px",
                                              mt: "8px",
                                              mr: "8px",
                                              cursor: "pointer",
                                              backgroundColor: index % 2 == 0 ? evenColor : oddColor,
                                            }}
                                            key={index}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                py: "12px",
                                                pr: "8px",
                                                pl: "12px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "16px",
                                                  fontWeight: "450",
                                                }}
                                              >
                                                {project}
                                              </Typography>
                                             
                                            </Box>
                                          </Box>
                                        );
                                    })
                                  ) : (
                                    <Box
                                    sx={{
                                      width: '100%',
                                      height: '100%',

                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: 1
                                    }}
                                  >
                                    <Box sx={{ height: "150px", width: "150px" }}>
                                      <Image src={noLessonFound} alt="No Lessons Found" objectFit="contain" />

                                    </Box>
                                    <Typography sx={{ fontSize: "16px", fontWeight: '400px', color: '#8C8C8C' }}>
                                      No Projects Completed</Typography>
                                    <Button sx={{ borderRadius: '4px', border: "1px solid #5C9391", color: "#5C9391", textTransform: "none" }} variant="outlined" onClick={() => {
                                      navigate(`/${currLanguage}/${MARKETPLACE}`)
                                    }}>
                                      <Typography sx={{ fontSize: '16px', fontWeight: '450' }}>Go to Lesson Plan Library</Typography>
                                    </Button>
                                  </Box>
                                  ))
                                }
                            </Box>
                          </Box>
                          {/* END OF CURRENTLY ON */}
                          {/* START OF TEACHERS AND CHILDREN */}
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              border: "1px solid #C4E4DF",
                              borderRadius: "4px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                p: "12px",
                              }}
                            >
                              {profileDetails.role != "TR" && (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      pb: "16px",
                                      height: "40%",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: "450",
                                        fontSize: "16px",
                                        color: "#5C9391",
                                        pb: "10px",
                                        padding: "12px",
                                      }}
                                    >
                                      {translation.teacher}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        flexWrap: "wrap",
                                        height: "100%",
                                        gap: "16px",
                                        overflowY: "auto",
                                        "&::-webkit-scrollbar": {
                                          width: "5px",
                                        },
                                      }}
                                    >
                                      {currGroup.teachers.map((teacher, index) => (
                                        <Box key={index}>
                                          <Person profile={teacher} />
                                        </Box>
                                      ))}
                                    </Box>
                                  </Box>
                                  <Divider sx={{ mx: 2 }} />
                                </>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  height: profileDetails.role == "TR" ? "100%" : "60%",
                                  maxHeight: profileDetails.role == "TR" ? "100%" : "60%",
                                }}
                              >
                                <Box sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start"
                                }}>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent:
                                          profileDetails.role == "TR" ? "flex-start" : "space-between",
                                        alignItems: "center",
                                        gap: 1,
                                        width: profileDetails.role == "TR" ? "auto" : "100%",

                                      }}
                                    >
                                      {!isSmallScreen && (
                                        <Typography
                                          sx={{
                                            fontWeight: "450",
                                            fontSize: isSmallScreen ? "14px" : "16px",
                                            color: "#5C9391",
                                          }}
                                        >
                                          {translation.children}
                                        </Typography>
                                      )}
                                      <Typography
                                        style={{
                                          fontWeight: "400",
                                          fontSize: isSmallScreen ? "12px" : "14px",
                                          color: "black",
                                        }}
                                      >
                                        {translation.total}: {currGroup.children.length}
                                        {"    "}
                                        {translation.children}
                                      </Typography>
                                    </Box>
                                    {profileDetails.role == "TR" && (
                                      <Box
                                        sx={{
                                          display: " flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          height: "100%",
                                        }}
                                      >
                                        <Button sx={{ textTransform: "none" }} onClick={() => {
                                          navigate(`/${currLanguage}/children-portfolio/${currGroup.slug}`);

                                        }}>
                                          <Typography
                                            sx={{
                                              fontSize: isSmallScreen ? "12px" : "14px",
                                              fontWeight: "450",
                                            }}
                                          >
                                            {translation.viewAll}
                                          </Typography>
                                        </Button>
                                        <ArrowForwardIosIcon sx={{ fontSize: "16px", color: "#5C9391" }} />
                                      </Box>
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      my: "10px",
                                      gap: "16px",
                                      alignItems: "flex-start",
                                      overflowY: "auto",
                                      "&::-webkit-scrollbar": {
                                        width: "5px",
                                      },
                                    }}
                                  >
                                    {currGroup.children.map((child, index) => (
                                      <Box key={index}>
                                        <Person profile={child} />
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>

                                {profileDetails.role != "TR" && (
                                  <Button
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                    onClick={() => {
                                      navigate(`/en/my-classes/${currGroup.slug}`);
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#5C9391",
                                        mr: 1,
                                        fontSize: "16px",
                                        textTransform: "none",
                                      }}
                                    >
                                      {translation.viewClass}
                                    </Typography>
                                    <ArrowForwardIosIcon sx={{ fontSize: "16px", color: "#5C9391" }} />
                                  </Button>
                                )}
                                {profileDetails.role == "TR" && (
                                  <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Button
                                      sx={{
                                        backgroundColor: "#91C6C5",
                                        borderRadius: "4px",
                                        marginTop: 3,
                                      }}
                                      variant="contained"
                                      onClick={() => {
                                        setAttendanceModalOpen(true);
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "white",
                                          fontSize: "16px",
                                          fontWeight: "450",
                                          textTransform: "none",
                                        }}
                                      >
                                        Take Attendance
                                      </Typography>
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box> : <Box
                          sx={{
                            width: '100%',
                            height: '100%',

                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 1
                          }}
                        >
                          <Box sx={{ height: "150px", width: "150px" }}>
                            <Image src={noAssignedClasses} alt="No Assigned Classes" objectFit="contain" />

                          </Box>
                          <Typography sx={{ fontSize: "16px", fontWeight: '400px', color: '#8C8C8C' }}>
                            You haven't been assigned any class yet</Typography>
                        </Box>
                      }


                      {/* END OF TEACHERS AND CHILDREN */}
                    </Box>
                  </Box>

                  {/* TIMETABLE COMPONENT */}
                  <Box
                    sx={{
                      flex: 1,
                      height: `calc(100vh - 128px)`,
                      backgroundColor: "white",
                      borderRadius: "4px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    ref={timetableRef}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          px: "12px",
                          py: "8px",
                        }}
                      >
                        {translation.timeTable}
                      </Typography>
                      <Typography
                        sx={{
                          px: "12px",
                          py: "8px",
                        }}
                      >
                        {today.format("D MMMM (dddd)")}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        overflowY: "auto",
                      }}
                    ></Box>

                    <FormControl
                      required
                      sx={{
                        maxHeight: "56px",
                        height: "56px",
                        width: "100%",
                        px: 2,
                      }}
                    >
                      <Select
                        sx={{
                          overflowY: "hidden",
                          maxHeight: "56px",
                          height: "56px",
                        }}
                        renderValue={(value) => {
                          if (value === "All Classes") {
                            return "All Classes";
                          } else {
                            const group = groupList.find((group) => group.name === value);
                            if (group) {
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    src={group.image ? group.image : Logo}
                                    sx={{
                                      width: "24px",
                                      height: "24px",
                                      display: "flex",
                                    }}
                                  />
                                  <Typography>{group.name}</Typography>
                                </Box>
                              );
                            }
                          }
                        }}
                        value={timetableClass}
                        onChange={(event) => {
                          setTimetableClass(event.target.value);
                        }}
                      >
                        {groupList.map((group: any, index: number) => (
                          <MenuItem value={group.name} key={index}>
                            {group.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box
                      sx={{
                        overflowY: "auto",
                        width: "100%",
                        p: 2,
                      }}
                    >
                      {timetableClass === "All Classes" && allEvents
                        ? Object.keys(allEvents).map((key) => (
                          <Box key={key} sx={{}}>
                            <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>{key}</Typography>
                            <ul>
                              {allEvents[key].map((event: any) => {
                                const groupName = groupList.find(
                                  (group: any) => group.id === event.currGroup
                                );
                                const color = colors[event.color % colors.length];
                                return (
                                  <Box
                                    key={event.id}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "flex-end",
                                      backgroundColor: color,
                                      borderRadius: "4px",
                                      px: "8px",
                                      py: "4px",
                                      mb: 1,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {" "}
                                      {groupName ? groupName.name : "Unknown"}
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "450",
                                          color: "#8C8C8C",
                                        }}
                                      >
                                        {event.name}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {event.start}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </ul>
                          </Box>
                        ))
                        : allEvents
                          ? Object.keys(allEvents).map((key) => {
                            const group = groupList.find((group: any) => {
                              return group.name == timetableClass;
                            });
                            const filteredEvents = allEvents[key].filter(
                              (event: any) => event.currGroup === group?.id
                            );

                            if (filteredEvents.length > 0) {
                              return (
                                <Box key={key}>
                                  <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>{key}</Typography>
                                  <ul>
                                    {filteredEvents.map((event: any) => {
                                      const groupName = groupList.find(
                                        (group: any) => group.id === event.currGroup
                                      );
                                      const color = colors[event.color % colors.length];
                                      return (
                                        <Box
                                          key={event.id}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "flex-end",
                                            backgroundColor: color,
                                            borderRadius: "4px",
                                            px: "8px",
                                            py: "4px",
                                            mb: 1,
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            {groupName ? groupName.name : "Unknown"}
                                            <Typography
                                              sx={{
                                                fontSize: "14px",
                                                fontWeight: "450",
                                                color: "#8C8C8C",
                                              }}
                                            >
                                              {event.name}
                                            </Typography>
                                          </Box>
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {event.start}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                  </ul>
                                </Box>
                              );
                            }
                            return null;
                          })
                          : null}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <WalkthroughSteps mode="dashboard" />
          </>
      )
      }
    </>
  );
};

export default Dashboard;
