import WalkthroughIntro from "./components/Intro/Intro";
import WalkthroughStep from "./components/WalkthroughStep";
import { getLinearElementDetails } from "./computeTeacherDashboardSteps";
import addNewChildIcon from "images/add-new-child.png";
import adminPageIcon from "images/admin-page.png";
import type { Step, SetOpen, EmptyFn } from "./types";


const addClassStepId = "admin-add-class";
const calendarStepId = "navbar-menu-block-calendar";

export const getHorizontalElementDetails = (elementId: string, height: number) => { 
  const element = document.getElementById(elementId);

  const elementHeight = element?.offsetHeight ?? 0;
  const elementWidth = element?.clientWidth ?? 0;
  const elementTop = (element?.offsetTop ?? 0); // 88 is constant considered that top is a relative position
  const elementLeft = (element?.offsetLeft ?? 0);

  return {
    left: elementLeft + elementWidth - 10,
    top: elementTop - ((height - elementHeight) / 2),
    html: element?.innerHTML ?? "",
  }
}

const computeTeacherDashboardSteps = (
  setOpen: SetOpen,
  handleBackStep: EmptyFn,
  handleNextStep: EmptyFn,
  handleClose: EmptyFn,
  isSm: boolean,
  isLg: boolean
) => {

  const introStep = () => {
    const width = isSm ? 276 : 415;

    return {
      width,
      height: "fit-content",
      top: (window.innerHeight - 134) / 2,
      left: (window.innerWidth - width) / 2,
      component: (
        <WalkthroughIntro
          role="admin"
          handleNext={handleNextStep}
          handleClose={() => handleClose()}
        />
      ),
    }
  };

  const addClassStep = (): Step => {
    const addClassElement = getHorizontalElementDetails(addClassStepId, isLg ? 240 : 337); 
    
    return {
      width: isSm ? 240 : 300,
      height: isLg ? 240 : 337,
      top: addClassElement.top,
      left: addClassElement.left,
      line: "horizontal",
      component: (
        <WalkthroughStep
          key={addClassStepId}
          handleNext={handleNextStep}
          handleBack={handleBackStep}
          steps={4} step={1}
          html={addClassElement.html}
          wrapperStyles={{
            width: { xs: "50px", lg: "80px" },
            height: { xs: "50px", lg: "80px" },
            color: "#444",
            border: "1px dashed",
            borderColor: "#444",
            borderRadius: "50%",
          }}
          pointerWrapperStyles={{
            display: {xs: "none", lg: "block" },
            top: "120px" ,
            left: "140px" 
          }}
          description='Click “Add class" to create a new class'
        />
      ),
    };
  };

  const calendarStep = (): Step => {
    const { top, left, html } = getLinearElementDetails(calendarStepId, 300, isLg ? 'mobile-menu-drawer' : undefined);

    return {
      width: isLg ? 276 : 300,
      height: "fit-content",
      top,
      left: isLg ? left + 50 : left,
      line: "vertical",
      mobileMenu: true,
      component: (
        <WalkthroughStep
          key={calendarStepId}
          handleNext={handleNextStep}
          handleBack={handleBackStep}
          steps={4} step={2}
          html={html}
          description="View all classes and ongoing projects in the scheduler"
        />
      ),
    };
  };

  const addNewChildStep = (): Step => ({
    width: 300,
    height: "fit-content",
    top: 40,
    right: 20,
    line: "curved-right",
    mobileMenu: false,
    component: (
      <WalkthroughStep
        key={calendarStepId}
        handleNext={handleNextStep}
        handleBack={handleBackStep}
        steps={4} step={3}
        image={addNewChildIcon}
        description="Click “Add new child” to a new child account"
        pointerWrapperStyles={{
          display: {xs: "none", lg: "block" },
          top: "68.09px",
          left: "194px"
        }}
      />
    ),
  });

  const adminPageStep = (): Step => ({
    width: 300,
    height: "fit-content",
    top: 40,
    right: 20,
    line: "curved-right",
    component: (
      <WalkthroughStep
        key={calendarStepId}
        handleNext={handleNextStep}
        handleBack={handleBackStep}
        steps={4} step={4}
        image={adminPageIcon}
        description="Manage teachers and student data in the admin page."
        pointerWrapperStyles={{
          display: {xs: "none", lg: "block" },
          top: "90px",
          left: "204px"
        }}
      />
    ),
  });

  return [
    introStep,
    addClassStep,
    calendarStep,
    addNewChildStep,
    adminPageStep
  ];
};

export default computeTeacherDashboardSteps;
